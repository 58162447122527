/**
 * If user has permission for this restriction
 */
const checkIfUserCan = (restrictions, modelCan) => {
  if (restrictions.isArray) {
    if (restrictions.every(permission => modelCan?.includes(permission))) {
      return true
    }
    return false
  }

  // has a single restriction
  if (modelCan?.includes(restrictions)) {
    return true
  }
  return false
}

export default {
  install (Vue) {
    Vue.mixin({
      methods: {
        /**
         * If user can "Entity/edit" on $api.Entity.details
         * @param {string, array} restriction - Permission names
         * @param {object} model
         * @returns {boolean}
         */
        userCan (restriction, model = null) {
          /**
           * @todo - Check also for admin
           */
          if (this.$api.Auth.user.roles?.includes("Administrators")) {
            return true
          }

          if (!model || !model.model_can) {
            if (!checkIfUserCan(restriction, this.$api.Auth.user.permissions)) {
              return false
            }
            return true
          }

          if (!checkIfUserCan(restriction, this.$api.Auth.user.permissions) && !checkIfUserCan(restriction, model.model_can)) {
            return false
          }
          return true
        },
        userHasRole (role) {
          if (!role) {
            return false
          }
          if (this.$api.Auth.user.roles?.includes(role)) {
            return true
          }
          return false
        }
      }
    })
  }
}
