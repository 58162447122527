import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/analytics",
    name: "Analytics",
    meta: { title: "HR analitika" },
    component: () => import(/* webpackChunkName: "Analytics List" */ "@/Modules/Analytics/Views/View"),
    beforeEnter: ifAuthenticated
  }
]
