// import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/administration",
    name: "Administration",
    meta: { title: "Administracija" },
    component: () => import(/* webpackChunkName: "Administration" */ "@/Modules/Administration/Views/Default")
    // beforeEnter: ifAuthenticated
  }
]
