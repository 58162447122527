export default {
  views: {
    list: {
      header: {
        title: "Ponudbe",
        search_placeholder: "Iskanje ...",
        add_button: "Nova ponudba",
        refresh_button: "Pridobi ponudbe",
        notify_success: "Pridobivanje ponudb smo postavili v vrsto za pridobivanje podatkov."
      },
      table: {
        title: "Naziv",
        work_field: "Delovno mesto",
        salary: "Honorar",
        candidates_count: "Število kandidatov",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        options: "Možnosti"
      }
    },
    details: {
      header: {
        back_button: "Na listo ponudb",
        edit_button: "Uredi ponudbo"
      },
      basic: {
        work_field: "Delovno mesto",
        candidates_count: "Število kandidatov",
        salary: "Honorar",
        warranty: "Garancijsko obdobje (št. mesecev)",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        get_offer: "Ponovno pridobi podatke o ponudbi",
        create_contract: "Ustvari pogodbo"
      },
      contacts: {
        edit_button: "Uredi kontakte",
        contacts_popup: {
          save_button: "Shrani"
        }
      }
    }
  },
  popup: {
    title: "Nova ponudba",
    fields: {
      title: {
        label: "Naziv"
      },
      company: {
        label: "Podjetje",
        search: {
          not_found: "Podjetje ne obstaja",
          add_button: "Dodaj podjetje"
        }
      },
      work_field: {
        label: "Delovno mesto"
      },
      candidates_count: {
        label: "Število kandidatov"
      },
      warranty: {
        label: "Garancijsko obdobje (št. mesecev)"
      },
      salary: {
        label: "Honorar"
      }

    },
    add_button: "Shrani ponudbo",
    update_button: "Shrani ponudbo"
  },
  contract_popup: {
    title: "Ustvari pogodbo iz ponudbe",
    save_button: "Shrani",
    fields: {
      title: "Naziv pogodbe",
      responsible: {
        label: "Odgovorna oseba",
        search: {
          not_found: "Oseba ne obstaja"
        }
      }
    }
  },
  prompts: {
    delete: {
      title: "Izbris ponudbe {title}",
      message: "Ste prepričani da želite izbrisati ponudbo {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili ponudbo {title}",
    updated: "Uspešno ste posodobili ponudbo {title}",
    deleted: "Ponudba {title} je bila izbrisana",
    not_found: "Ponudba ni bila najdena"
  }
}
