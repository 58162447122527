export default {
  views: {
    list: {
      header: {
        title: "Soglasja",
        search_placeholder: "Iskanje...",
        add_button: "Novo soglasje"
      },
      table: {
        title: "Naziv",
        language: "Jezik",
        type: "Tip",
        author: "Avtor",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        active: "Aktivno",
        options: "Možnosti"
      }
    },
    details: {
      title: "Novo soglasje",
      fields: {
        title: {
          label: "Naziv"
        },
        type: {
          label: "Tip"
        },
        language: {
          label: "Jezik"
        },
        content: {
          label: "Vsebina"
        },
        agreementText: {
          label: "Sprejemni tekst",
          placeholder: "Soglašam z navadenimi pogoji"
        },
        title_en: {
          label: "Naziv (Angleščina)"
        },
        content_en: {
          label: "Vsebina (Angleščina)"
        },
        agreementText_en: {
          label: "Sprejemni tekst (Angleščina)",
          placeholder: "Soglašam z navadenimi pogoji"
        }
      },
      add_button: "Shrani soglasje",
      update_button: "Shrani soglasje"
    }
  },
  prompts: {
    delete: {
      title: "Izbris soglasja {title}",
      message: "Ste prepričani da želite izbrisati soglasje {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili soglasje {title}",
    updated: "Uspešno ste posodobili soglasje {title}",
    deleted: "Uspešno ste izbrisali soglasje {title}",
    not_found: "Soglasje ni bilo najdeno"
  }
}
