import LaravelResourceRepository from "@/providers/api/repositories/LaravelResourceRepository"

export default class Project extends LaravelResourceRepository {
  route = "projects"
  namespace = "projects"
  identifier = "uid"

  state = {
    listDefaultParams: {
      with: ["createdBy", "updatedBy", "company", "responsible", "hiringManager", "testing", "ad", "responsiblePerson.relations"]
    },
    detailsDefaultParams: {
      with: ["createdBy", "updatedBy", "company.contacts", "responsible", "hiringManager", "ad", "contract.media", "statuses", "access", "responsiblePerson.relations", "testing.users.user.competences", "testing.users.opinionBy", "testing.users.media", "testing.users.user.questionnaireAnswers", "funnel", "testing.users.summaryBy", "researcher", "salesman", "consultant", "testing.users.user.relations"]
    }
  }

  actions = {
    /**
       * Add/remove user on testing
       *
       * @param {object} params
       *
       * @returns {object}
       */
    orderTesting: async ({ commit }, params) => {
      let response = await this.request({ namespace: "orderTesting", loaderArrayValue: params.params.id }).post(`/projects/${params.params.id}/order-testing`, params.params)

      return response
    },
    updateStatus: async ({ commit }, params) => {
      let response = await this.request({ namespace: "updateStatus" }).put(`/projects/${params.id}/update-status`, params.params)

      return response
    },
    notify: async ({ commit }, params) => {
      let response = await this.request({ namespace: "notify", loaderArrayValue: params.id }).post(`/projects/${params.id}/notify`, params.params)

      return response
    },
    sendQuestionnaire: async ({ commit }, params) => {
      let response = await this.request({ namespace: "sendQuestionnaire", loaderArrayValue: params.params.id }).post(`/projects/${params.id}/send-questionnaire`, params.params)

      return response
    },
    sendNotification: async ({ commit }, params) => {
      let response = await this.request({ namespace: "sendNotification" }).post("/projects/send-notification", params)

      return response
    },
    close: async ({ commit }, params) => {
      let response = await this.request({ namespace: "close", loaderArrayValue: params.id }).post(`/projects/${params.id}/close`, params.params)

      return response
    }
  }
}
