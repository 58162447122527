import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/candidates",
    name: "Candidates",
    meta: { title: "Kandidati" },
    component: () => import(/* webpackChunkName: "Candidates List" */ "@/Modules/Candidate/Views/List"),
    children: [
      {
        path: "new",
        name: "New Candidate",
        meta: { title: "Nov kandidat" }
      },
      {
        path: "edit/:id",
        name: "Edit Candidate (List)"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/candidates/details/:id",
    name: "Candidate Details",
    component: () => import(/* webpackChunkName: "Candidate Details" */ "@/Modules/Candidate/Views/Details"),
    children: [
      {
        path: "edit",
        name: "Edit Candidate (Details)"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
