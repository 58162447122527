export default {
  views: {
    list: {
      header: {
        title: "Identi",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj"
      },
      table: {
        code: "Šifra",
        group: "Naziv grupe",
        description: "Opis",
        options: "Možnosti"
      }
    }
  },
  popup: {
    title: "Nov ident",
    fields: {
      code: "Šifra",
      group: "Grupa",
      description: "Opis"
    },
    add_button: "Shrani",
    update_button: "Shrani"
  },
  prompts: {
    delete: {
      title: "Izbris identa {code}",
      message: "Ste prepričani da želite izbrisati ident {code}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili ident {code}",
    updated: "Uspešno ste posodobili ident {code}",
    deleted: "Uspešno ste izbrisali ident {code}",
    not_found: "Ident ni bil najden"
  }
}
