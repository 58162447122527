export default {
  views: {
    list: {
      header: {
        title: "Ocenjevanja",
        search_placeholder: "Iskanje ...",
        add_button: "Novo ocenjevanje"
      },
      table: {
        title: "Naziv",
        responsible: "Odgovorna oseba",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        active: "Aktivno",
        options: "Možnosti",
        behavior_level: "Nivo vedenja"
      },
      behavior_levels: {
        leaders: "Vodje",
        others: "Ne vodje"
      }
    },
    details: {
      header: {
        back_button: "Na listo ocenjevanj",
        edit_button: "Uredi ocenjevanje",
        toggle: {
          title: "Aktivno",
          activate: "aktivirate",
          deactivate: "deaktivirate",
          description: "Ocenjevanje lahko {action} tukaj"
        }
      },
      basic: {
        responsible: "Odgovorna oseba",
        project: "Projekt",
        document: "Pripet dokument",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        evaluators: {
          title: "Ocenjevalci",
          text: "Upravljanje ocenjevalcev"
        },
        comment: "Opomba",
        empty_list: "Lista ocenjevalcev je prazna.",
        behavior_level: {
          label: "Nivo vedenja",
          leaders: "Vodje",
          others: "Ne vodje"
        }
      },
      competences: {
        headline: "Kompetence",
        headline_description: "Kompetence ocenjevanja",
        buttons: {
          edit: "Upravljanje kompetenc",
          save: "Shrani kompetence"
        },
        input: {
          placeholder: "Tukaj vpišite novo kompetenco"
        },
        error_message: "Pri shranjevanju je prišlo do napake.",
        success_message: "Uspešno ste shranili kompetence."
      },
      competences_widget: {
        headline: "Kompetence",
        headline_description: "Kompetence ocenjevanja",
        buttons: {
          add: "Dodaj kompetence",
          remove: "Izbriši kompetenco"
        },
        table: {
          title: "Naziv"
        },
        prompts: {
          delete: {
            title: "Izbris kompetence {title}",
            message: "Ste prepričani da želite izbrisati kompetenco {title}?",
            cancel: "Prekliči",
            confirm: "Izbriši"
          }
        }
      },
      observations_widget: {
        headline: "Opazovalni listi",
        headline_description: "Opazovalni listi ocenjevanja",
        buttons: {
          add: "Uredi opazovalne liste",
          remove: "Izbriši opazovalni list"
        },
        table: {
          competence: "Kompetenca",
          assignment: "Naloga"
        },
        prompts: {
          delete: {
            title: "Izbris opazovalnega lista?",
            message: "Ste prepričani da želite izbrisati opazovalni list?",
            cancel: "Prekliči",
            confirm: "Izbriši"
          }
        }
      },
      observations_drawer: {
        title: "Opazovalni listi",
        save_button: "Shrani",
        cancel_button: "Prekliči",
        note: "Izberite kombinacije kompetenc in nalog. Posamezna kombinacija bo tvorila opazovalni list.",
        error_message: "Pri shranjevanju je prišlo do napake.",
        success_message: "Uspešno ste shranili opazovalne liste."
      },
      assignments_widget: {
        headline: "Naloge",
        headline_description: "Naloge ocenjevanja",
        buttons: {
          edit: "Uredi naloge",
          save: "Shrani naloge",
          assignment: {
            save: "Posodobi nalogo",
            delete: "Odstrani nalogo"
          }
        },
        input: {
          placeholder: "Tukaj vpišite novo nalogo"
        },
        error_message: "Pri shranjevanju je prišlo do napake.",
        success_message: "Uspešno ste shranili naloge."
      },
      candidates_list: {
        headline: "Ocenjevanje kandidatov",
        buttons: {
          candidates_popup: {
            text: "Upravljanje kandidatov"
          },
          grade_popup: {
            grade: {
              text: "Oceni"
            },
            grade_assesment_popup: {
              text: "Oceni (psiholog)"
            },
            correction_drawer: {
              text: "Korekcija"
            }
          },
          table_actions: {
            grade: "Oceni kandidata",
            correction_drawer: "Korekcija ocen",
            show_candidate: "Prikaži kandidata"
          },
          export: "Izvozi opazovalne liste"
        },
        table: {
          name: "Ime in priimek",
          status: "Status",
          created_at: "Ocenjeno dne",
          options: "Možnosti"
        },
        empty_list: {
          p1: "Lista za ocenjevanje kandidatov je prazna.",
          p2: "Kandidate lahko dodate tako, da odprete listo za upravljanje kandidatov",
          p3: "Upravljanje liste kandidatov"
        },
        candidates_popup: {
          title: "Upravljanje liste kandidatov",
          search: "Iskanje ...",
          table: {
            name: "Ime in priimek"
          },
          buttons: {
            add: "Dodaj novega kandidata",
            cancel: "Prekliči",
            save: "Shrani"
          }
        },
        grade_popup: {
          candidate: "Kandidat",
          details: "Podrobnosti",
          reset: "Ponastavi",
          buttons: {
            save: "Shrani oceno"
          },
          get_grade: {
            error_message: "Pri poizvedbi ocen je prišlo do napake."
          },
          confirm: {
            error_message: "Pri shranjevanju je prišlo do napake.",
            success_message: "Uspešno ste shranili oceno."
          }
        },
        grade_observation_popup: {
          candidate: "Kandidat",
          details: "Podrobnosti",
          reset: "Ponastavi",
          buttons: {
            save: "Shrani oceno"
          },
          get_grade: {
            error_message: "Pri poizvedbi ocen je prišlo do napake."
          },
          confirm: {
            error_message: "Pri shranjevanju je prišlo do napake.",
            success_message: "Uspešno ste shranili oceno."
          },
          competence: "Kompetenca",
          assignment: "Naloga",
          rating: "Končna ocena",
          indicators: {
            description: {
              positive: "Pozitivni vedenjski indikatorji",
              negative: "Negativni vedenjski indikatorji"
            },
            first: {
              positive: "Postavlja SMART (specifične, merljive, dosegljive, relevantne,  časovno opredeljene) cilje in jasen načrt aktivnosti.",
              negative: "Postavlja nejasne ali nekonkretne cilje. Dogovor z zaposlenim ne vključuje jasnega načrta aktivnosti."
            },
            second: {
              positive: "Pri načrtovanju aktivnosti si postavlja visoke cilje in ni zadovoljen zgolj s povprečnim.",
              negative: "Pri načrtovanju aktivnosti se hitro zadovolji z rešitvijo, ne razmišlja, kako bi ideje nadgradil."
            },
            third: {
              positive: "Pri reševanju izzivov uporablja različne strategije in pristope.",
              negative: "Za reševanje problemov uporablja enake, ustaljene rešitve in pristope."
            },
            fourth: {
              positive: "Spodbuja proaktiven pristop in oblikuje primeren nivo pritiska na druge ljudi.",
              negative: "Sprejema pasivni pristop ter izgovore za zamude in nedoseganje ciljev."
            },
            fifth: {
              positive: "Tudi ko naleti na ovire in izzive, ohrani vztrajnost, odločnost in pozitiven pristop.",
              negative: "Ko je soočen z izzivi in ovirami na poti do cilja, hitro popusti in se preusmeri drugam."
            }
          },
          examples: {
            positive: "Primeri pozitivnih vedenj",
            negative: "Primeri negativnih vedenj"
          },
          helper: "1: zelo slabo razvidno</br>2: slabo razvidno</br>3: dobro razvidno</br>4: zelo dobro razvidno</br>5: odlično razvidno</br>X: ne moremo oceniti"
        }
      },
      corrections_drawer: {
        title: "Ocenjevanje kandidata",
        candidate: "Kandidat",
        name: "Ime in priimek",
        correction: "Korekcija",
        corrections: {
          title: "Korekcija"
        },
        assessments_psychologists: {
          title: "Ocena psihologa",
          testing: "Psihološko testiranje"
        },
        assessments_evaluators: {
          title: "Ocene ocenjevalcev",
          toggle: "Prikaži naloge",
          avg: "Povprečje"
        },
        average: "Aritmetična sredina",
        grade_popup: {
          title: "Korekcija kandidata"
        },
        error_message: "Pri poizvedbi ocen je prišlo do napake."
      },
      users_popup: {
        title: "Upravljanje ocenjevalcev",
        search: "Iskanje ...",
        table: {
          name: "Ime in priimek",
          psychologist: "Vloga psihologa",
          presence: "Prisotnost v ocenjevanju"
        }
      },
      add_competence_drawer: {
        title: "Dodajanje kompetenc",
        search: "Iskanje ...",
        tag: "Število kompetenc: ",
        table: {
          title: "Naziv",
          code: "Koda"
        },
        cancel_button: "Prekliči",
        save_button: "Dodaj"
      }
    }
  },
  popup: {
    title: "Novo ocenjevanje",
    fields: {
      title: {
        label: "Naziv ocenjevanja",
        placeholder: "Vpišite naziv ocenjevanja"
      },
      document: {
        headline: "Dokument",
        headline_description: "Izbran dokument bo uporabljen kot predloga pri prenosu poročila.",
        label: "Dokument",
        search: {
          not_found: "Dokument ne obstaja"
        }
      },
      comment: {
        label: "Opomba"
      },
      responsible: {
        label: "Odgovorna oseba",
        not_found: "Oseba ne obstaja"
      },
      company: {
        label: "Podjetje",
        search: {
          not_found: "Podjetje ne obstaja",
          add_button: "Dodaj podjetje"
        }
      },
      behavior_level: {
        label: "Nivo vedenja"
      }
    },
    behavior_levels: {
      leaders: "Vodje",
      others: "Ne vodje"
    },
    add_button: "Shrani ocenjevanje",
    update_button: "Shrani ocenjevanje"
  },
  prompts: {
    delete: {
      title: "Izbris ocenjevanja {title}",
      message: "Ste prepričani da želite izbrisati ocenjevanje {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili ocenjevanje {title}",
    updated: "Uspešno ste posodobili ocenjevanje {title}",
    deleted: "Uspešno ste izbrisali ocenjevanje {title}",
    not_found: "Ocenjevanje ni bilo najdeno"
  }
}
