export default {
  views: {
    list: {
      key: "Ključ",
      data: "Vrednost",
      updated_at: "Posodobljeno",
      created_at: "Ustvarjeno",
      title: "Ime",
      title_en: "Ime (Angleščina)",
      category_id: "Kategorija",
      color: "Barva",
      icon: "Ikona",
      desc: "Opis",
      groupId: "Ključ skupine",
      groupName: "Ime skupine",

      save: "Shrani",
      remove: "Odstrani",
      remove_category_msg: "Šifrant {title} uspešno odstranjen.",
      remove_msg: "Vnos uspešno odstranjen.",
      remove_error: "Prišlo je do napake pri odstrnjevanju vnosa.",
      updated_msg: "Vnos uspešno posodobljen.",
      update_error: "Error has occurred while updating an item.",
      store_error: "Error has occurred while storing an item."
    },
    header: {
      title: "Šifranti",
      add: "Dodaj vnos"
    },
    category: {
      title: "Kategorije"
    }
  }
}
