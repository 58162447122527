import LaravelResourceRepository from "@/providers/api/repositories/LaravelResourceRepository"

export default class NotificationTemplate extends LaravelResourceRepository {
  route = "notification-templates"
  namespace = "notification-templates"
  identifier = "uid"

  state = {
    listDefaultParams: {
      with: ["createdBy", "updatedBy"]
    },
    detailsDefaultParams: {
      with: ["createdBy", "updatedBy"]
    }
  }
}
