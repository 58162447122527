export default {
  views: {
    list: {
      header: {
        title: "Kompetence",
        search_placeholder: "Iskanje ...",
        add_button: "Nova kompetenca"
      },
      table: {
        title: "Naziv",
        code: "Koda",
        type: "Tip",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        options: "Možnosti"
      }
    }
  },
  widget: {
    title: "Splošne kompetence"
  },
  drawer: {
    title: "Nova kompetenca",
    tabs: {
      properties: "Lastnosti",
      assessment: "Opis",
      opinion: "Sklepno mnenje",
      card: "Kompetenčna kartica"
    },
    properties: {
      fields: {
        type: {
          label: "Tip kompetence",
          placeholder: "Izberite tip kompetence"
        },
        code: {
          label: "Koda kompetence",
          placeholder: "Vpišite kodo"
        },
        lang: {
          remove: "Odstrani",
          competence: {
            label: "Naziv kompetence",
            placeholder: "Vpišite naziv kompetence"
          },
          lang: {
            label: "Jezik",
            placeholder: "Izberite jezik"
          },
          description: {
            label: "Opis"
          }
        }
      },
      add_language: "Dodaj jezik"
    },
    conditions: {
      title: "Pogoji",
      add_condition: "Dodaj pogoj",
      delete_condition: "Izbriši pogoj",
      add_language: "Dodaj jezik",
      condition: "Pogoj",
      rate: "Ocena",
      remove: "Odstrani",
      default: "Privzeto",
      lang: {
        label: "Jezik",
        placeholder: "Izberite jezik",
        content: {
          male: "Moški",
          female: "Ženska"
        }
      },
      removePrompt: {
        title: "Izbris pogoja",
        message: "Ste prepričani da želite izbrisati pogoj?",
        confirmBtn: "Izbriši",
        cancelBtn: "Prekliči"
      }
    },
    competence_card: {
      title: "Kategorije",
      categories: {
        "about": "Opis",
        "work_field": "Področje dela",
        "verification": "Način preverjanja",
        "behaviors": "Vedenja",
        "questions": "Vprašanja za vedenjski intervju",
        "behaviors_rc": "Vedenja za OC",
        "examples_rc": "Dodatek vedenj OC za vodje"
      },
      about: {
        add_language: "Dodaj jezik",
        remove: "Odstrani",
        lang: {
          label: "Jezik",
          placeholder: "Izberite jezik",
          description: "Opis"
        }
      },
      work_field: {
        add_language: "Dodaj jezik",
        remove: "Odstrani",
        lang: {
          label: "Jezik",
          placeholder: "Izberite jezik",
          description: "Področje dela"
        }
      },
      verification_method: {
        add_language: "Dodaj jezik",
        remove: "Odstrani",
        lang: {
          label: "Jezik",
          placeholder: "Izberite jezik",
          description: "Način preverjanja"
        }
      },
      behaviors: {
        add_language: "Dodaj jezik",
        remove: "Odstrani",
        lang: {
          label: "Jezik",
          placeholder: "Izberite jezik",
          description: "Vedenja"
        }
      },
      behaviors_rc: {
        add_language: "Dodaj jezik",
        add_behavior: "Dodaj indikator",
        remove: "Odstrani",
        lang: {
          label: "Jezik",
          placeholder: "Izberite jezik",
          description: "Vedenja za OC"
        },
        indicators: {
          positive: "Pozitivni vedenjski indikator",
          negative: "Negativni vedenjski indikator"
        }
      },
      examples_rc: {
        add_language: "Dodaj jezik",
        add_example: "Dodaj primer",
        remove: "Odstrani",
        lang: {
          label: "Jezik",
          placeholder: "Izberite jezik",
          description: "Dodatek vedenj OC za vodje"
        },
        examples: {
          positive: "Primer pozitivnega vedenja",
          negative: "Primer negativnega vedenja"
        }
      },
      questions: {
        add_language: "Dodaj jezik",
        remove: "Odstrani",
        lang: {
          label: "Jezik",
          placeholder: "Izberite jezik",
          description: "Vprašanja za vedenjski intervju"
        }
      }
    },
    add_button: "Shrani kompetenco",
    update_button: "Shrani kompetenco",
    close_prompt: {
      title: "Zapirate kompetenco",
      message: "Morda imate spremembe, ki niso shranjene. Ste prepričani da želite zapreti kompetenco?"
    }
  },
  prompts: {
    delete: {
      title: "Izbris {title}",
      message: "Ste prepričani da želite izbrisati kompetenco {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili kompetenco {title}",
    updated: "Uspešno ste posodobili kompetenco {title}",
    deleted: "Uspešno ste izbrisali kompetenco {title}",
    not_found: "Kompetenca ni bila najdena"
  }
}
