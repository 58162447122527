export default {
  views: {
    list: {
      header: {
        title: "Vprašalniki",
        search_placeholder: "Iskanje ...",
        add_button: "Nov vprašalnik"
      },
      table: {
        title: "Naziv",
        status: "Stanje",
        language: "Jezik",
        author: "Avtor",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        active: "Aktiven",
        options: "Možnosti",
        lock: {
          locked: "Zaklenjen",
          open: "Odprt"
        },
        duplicate: "Dupliciraj",
        hr_analytics: "HR analitika",
        category: "Kategorija"
      },
      lock_popup: {
        title: "Vprašalnik je v uporabi",
        questionnaire: "Vprašalnik",
        is_locked: "je zaklenjen.",
        explanation: "Zaradi zaščite se vprašalnik samodejno zaklene ob uporabi v meritvi. Spreminjanje dokumenta lahko povzroči izgubo rezultatov.",
        first_notice: "Urejanje lahko povzoroči izgubo obsotoječih rezultatov. Če meritev še nima rezultatov, spremembe vprašalnika nanjo ne vplivajo.",
        second_notice: "Stanje rezultatov lahko preverite v meritvi.",
        third_notice: {
          explanation: "Pri meritvah z rezultati, lahko urejate naslednje:",
          first_item: "Lastnosti vprašalnika",
          second_item: "Besedila in slike",
          third_item: "Oznake lestvic (odgovorov)"
        },
        advice: "Pred urejanjem svetujemo, da podvojite vprašalnik in s tem ustvarite varnostno kopijo. V primeru, da kasneje izvedete za morebitne napake, lahko vprašalnik v meritvi spremenite na kopijo stare različice.",
        continue: "Nadaljuj v vprašalnik"
      }
    },
    editor: {
      builder: {
        answer: {
          id: "ID",
          copied: "Kopirano",
          copyText: "Kopiraj",
          options: {
            type: {
              scale: "Lestvica",
              list: "Seznam",
              input: "Prosti vnos",
              table: "Tabela",
              distribute: "Tabela 100"
            },
            choiceFormat: {
              single: "En odgovor",
              multiple: "Več odgovorov"
            }
          },
          fields: {
            connection: {
              label: "Ključ",
              value: "Ni obstoječih ključev",
              placeholder: "Ni obstoječih ključev"
            },
            type: {
              label: "Tip odgovorov",
              possible_answers: "Možni odgovori"
            },
            attrs: {
              numbering: {
                label: "Oštevilči postavko"
              },
              invert: {
                label: "Obratno vrednotenje"
              },
              condition: {
                title: "Pogoj",
                label: "Pogojni prikaz",
                mark: "Oznaka postavke",
                disable: "Onemogočite pogojni prikaz",
                description_enabled: "Postavka bo prikazana v primeru izpolnjenega pogoja.",
                description_disabled: "Pogojni prikaz na 1. postravki ni mogoč, saj se pogoj lahko navezuje le na predhodne postavke.",
                more_info: "Več informacij",
                shown_condition: "Prikazana postavka",
                condition_value: "Vrednost postavke",
                value: "Vrednost",
                answer: "Odgovor"
              },
              rowsLabel: {
                title: "Oznaka vrstic"
              },
              column: {
                title: "Naziv stolpca"
              },
              row: {
                title: "Naziv vrstice",
                statement: "Trditev"
              }
            }
          },
          duplicate_section: "Podvoji postavko",
          remove_section: "Odstrani postavko",
          buttons: {
            remove_answer: {
              title: "Odstrani odgovor"
            },
            new_condition: {
              title: "Nova postavka"
            },
            remove_column: {
              title: "Odstrani stolpec"
            },
            add_column: {
              title: "Dodaj stolpec"
            },
            add_row: {
              title: "Dodaj vrstico"
            },
            remove_row: {
              title: "Odstrani vrstico"
            }
          },
          handleTypeChange: {
            current: "Trenutno",
            wanted: "Željeno",
            statements: "Trditve"
          }
        },
        card: {
          buttons: {
            add_content: "Dodaj vsebino",
            add_answer: "Dodaj postavko",
            remove_page: "Odstrani stran"
          }
        },
        heading: {
          duplicate_section: "Podvoji vsebino",
          remove_section: "Odstrani vsebino"
        },
        questionnaire: {
          new_page: "Nova stran"
        }
      },
      condition_help_popup: {
        basic: {
          p1: "Pogojni prikaz je napredna funkcionalnost, ki omogoča prikazovanje in skrivanje postavk glede na vrednost predhodno izbranih odgovorov.",
          p2: "Za uporabo pogojnega prikaza je potrebno definirati pogoj v primeru katerega bo postavka prikazana med izpolnjevanjem.",
          p3: "Pogoje definiramo v obliki pogojnih stavkov, ki temeljijo na programskem jeziku. Tukaj lahko najdete vse potrebne informacije za ustvarjanje pogojev.",
          p4: "Osnovna struktura",
          p5: "Vsakemu pogoju definiramo 3 lastnosti - postavko, operator in vrednost.",
          p6: {
            title: "Postavka",
            text: {
              part1: " je referenca na izbran odgovor v sklopu določene postavke in njegovo vrednost ali prikazano besedilo. Definiramo jo v obliki ",
              part2: ", kjer ",
              part3: " predstavlja ID postavke."
            }
          },
          p7: {
            title: "Operator",
            text: {
              part1: " je simbol, ki definira kako bomo postavko in vrednost primerjali. Seznam operatorjev si lahko ogledate v zavihku ",
              part2: "Operatorji"
            }
          },
          p8: {
            title: "Vrednost",
            text: " je dejanska vrednost s katero bomo primerjali postavko oziroma izbran odgovor. Definiramo jo lahko na 2 načina:"
          },
          p9: {
            part1: "Kot število",
            part2: ", če primerjamo z vrednostjo izbranega odgovora."
          },
          p10: {
            part1: "Kot besedilo",
            part2: ", če primerjamo s prikazanim besedilom izbranega odgovora. Besedilo se razlikuje v tem, da vrednost definiramo znotraj narekovajev "
          },
          p11: "postavka, operator, vrednost",
          p12: {
            part1: "Pogojni stavki lahko vključujejo več pogojev, ki jih ločimo z operatorjema (in) ",
            part2: "ali"
          },
          p13: "prikazano besedilo",
          p14: "Več primerov si lahko ogledate v zavihku ",
          p15: "Primeri",
          p16: "Izjeme",
          p17: "Pogoji se lahko nanašajo tudi na postavke tipa prosti vnos. V tem primeru bo postavka prikazana v kolikor se besedilo v prostem vnosu ujema z besedilom, ki ga definirate v pogoju. Ker odgovor v prostem vnosu nima vrednosti, se pogoj lahko navezuje izključno na prikazano besedilo - ",
          p18: "zato ga vedno definiramo v narekovajih",
          p19: "Pogoji se ne morejo nanašati na:",
          p20: "postavke tipa Seznam, kjer uporabnik lahko izbere več odgovorov",
          p21: "postavke tipa Tabela",
          p22: "Nasveti in opozorila",
          p23: "Pogoji naj se nanšajo le na predhodne postavke. Tako lahko najbolje zagotovite, da uporabnik ne spregleda pogojno prikazane postavke.",
          p24: "Po končani izdelavi vprašalnika se prepričajte, da so vaši pogoji pravilni, tako da ga sami vsaj enkrat izpolnete in preverite pridobljene rezultate.",
          p25: "Skrite postavke lahko vplivajo na pravilno zaporedje številčenja - razlike so opazne le med različnimi stranmi.",
          p26: "Zaradi preglednosti dokumenta, ne puščajte vključene funkcionalnosti v kolikor niste definirali pogoja."
        },
        examples: {
          p1: "Postavka 1. ima izbran odgovor z vrednostjo ",
          p2: "Postavka 1. ima izbran odgovor, ki nima prikazane vrednosti (besedila) ",
          p3: "pogosto",
          p4: "pogosto",
          p5: "Postavka 1. ima izbran odgovor z vrednostjo ",
          p6: " in postavka 2. ima izbran odgovor z vrednostjo ",
          p7: "Postavka 1. ima izbran odgovor z vrednostjo ",
          p8: " in postavka 5. ima izbran odgovor, ki manjši ali enak kot ",
          p9: "Postavka 1. ima izbran odgovor z vrednostjo ",
          p10: ", ali",
          p11: "postavka 2. ima izbran odgovor z vrednostjo ",
          p12: "Postavka 1. ima izbran odgovor z vrednostjo ",
          p13: "postavka 5. ima izbran odgovor, ki manjši od ",
          p14: "postavka 9. ima izbran odgovor, ki je večji ali enak kot ",
          p15: "Postavka 1. ima izbran odgovor z vrednostjo ",
          p16: " in  postavka 5. ima izbran odgovor, ki manjši od ",
          p17: "postavka 9. ima izbran odgovor, ki je večji ali enak kot ",
          p18: "Postavka 1. in postavka 2. imata izbran odgovor z isto vrednostjo.",
          p19: "Vsota vrednosti izbranih odgovorov pri postavkah 1. in 2. je enaka vrednosti izbranega odgovora postavke 3.",
          p20: "Produkt vrednosti izbranih odgovorov pri postavki 1. in 2. je enak vrednosti izbranega odgovora postavke 3.",
          p21: "Vsota vrednosti izbranih odgovorov pri postavki 1. in 2. je enaka kvocientu vrednosti izbranega odgovora postavke 3. in števila "
        },
        operators: {
          operator: "Operator",
          description: "Opis",
          example: "Primer",
          list: {
            description1: "je enako",
            description2: "ni enako",
            description3: "je manjše",
            description4: "je večje",
            description5: "je manjše ali enako",
            description6: "je večje ali enako",
            description7: "in",
            description8: "ali",
            description9: "seštevanje",
            description10: "odštevanje",
            description11: "množenje",
            description12: "deljenje",
            description13: "ostanek pri deljenju"
          }
        },
        popup: {
          title: "Pogojni prikaz",
          tabs: {
            basic: "Splošno",
            operators: "Operatorji",
            examples: "Primeri"
          }
        }
      },
      drawer: {
        drawer: {
          text1: "Funkcije",
          text2: "Lastnosti"
        },
        properties: {
          fields: {
            title: {
              label: "Ime dokumenta",
              placeholder: "Vprašalnik"
            },
            category: {
              label: "Kategorija"
            },
            tags: {
              label: "Oznaka"
            },
            language: {
              label: "Jezik"
            },
            stats: {
              label: "Povprečen čas reševanja",
              placeholder: "10 min"
            },
            analytics: {
              label: "HR analitika"
            }
          },
          active: "Aktiven",
          locked: "Zaklenjen",
          unlock: "Odkleni",
          lock: "Zakleni",
          questions_count: "Število vprašanj",
          updated_by: "Nazadnje uredil",
          created_by: "Nazadnje uredil",
          created_at: "Ustvarjeno",
          updated_at: "Posodobljeno",
          category: {
            options: {
              testings: "Testiranja kandidatov",
              measures: "Meritve zaposlenih",
              projects: "Projekti - Povratna info."
            }
          },
          lang: {
            options: {
              sl: "Slovenščina",
              en: "Angleščina"
            }
          }
        },
        functions: {
          content: {
            keys: "Ključi"
          },
          function_popup: {
            //
            new_function: "Nova funkcija",
            fields: {
              name: {
                label: "Ime"
              },
              type: {
                label: "Tip"
              },
              key: {
                text1: "ID",
                text2: "Vnos"
              },
              value: {
                text1: "Prikaz",
                text2: "Iznos"
              }
            },
            content: "Vsebina",
            keys: "Ključi",
            conditions: "Pogoji",
            add_key: "Dodaj ključ",
            add_condition: "Dodaj pogoj",
            allow_multiple: {
              label: "Želite dodati še eno?",
              description: "Po potrditvi bo okno ostalo odprto"
            },
            buttons: {
              save: "Shrani",
              add: "Dodaj",
              function: " funkcijo"
            },
            optionsType: {
              value: "Vrednost",
              connection: "Ključi",
              method: "Poizvedba"
            },
            save_method: {
              function: "Funkcija '",
              exists: "' že obstaja",
              message1: "Funkcija 'connection' je rezervirana za ključe",
              message2: "Funkcija Ključi že obstaja"
            }
          }
        }
      },
      leave_prompt: {
        title: "Zapuščate urejevalnik",
        message: "Morda imate spremembe, ki niso shranjene. Ste prepričani da želite zapustiti urejevalnik?"
      },
      success_message: "Uspešno ste shranili vprašalnik"
    },
    details: {
      header: {
        headline: "Vprašalnik",
        tooltip: "Spremeni pogled",
        duration: "Čas reševanja",
        scope: {
          assessed_person: "Ocenjevana oseba",
          self: "samoocena"
        },
        save: {
          button: "Shrani vprašalnik",
          notify: "Vprašalnik je uspešno posodobljen"
        }
      },
      completed: {
        p1: "Uspešno ste zaključili",
        p2: "Zahvaljujemo se Vam za sodelovanje in želimo uspešen dan."
      },
      content: {
        components: {
          answer: {
            add_row: "Dodaj vrstico",
            sum: "Skupaj",
            cant_edit: "Spreminjanje odgovorov na zaključenih vprašalnikih ni mogoče",
            cant_edit_overview: "Spreminjanje odgovorov v pregledu ni mogoče"
          }
        }
      },
      slides: {
        navigation: {
          back: "Nazaj",
          close: "Zapri",
          end: "Zaključi",
          continue: "Nadaljuj"
        }
      },
      full: {
        content: {
          close: "Zapri vprašalnik",
          end: "Zaključi"
        }
      },
      questionnaire_360: {
        headline: "V vprašalniku boste podali povratno informacijo za osebo {name}. Opredelite vaše razmerje z omenjeno osebo.",
        self: "Samoocena - to sem jaz",
        leader: "Vodja - sem nadrejeni tej osebi",
        coworker: "Sodelavec - sem na istem nivoju tej osebi",
        employee: "Zaposleni - sem podrejeni tej osebi"
      }
    },
    open_questionnaire_popup: {
      title: "Odpri",
      overview: {
        headline: "Pregled",
        headline_desc: "Odpri vprašalnik za ogled rezultatov brez možnosti urejanja."
      },
      live: {
        headline: "Reševanje",
        headline_desc: "Odpri vprašalnik v privzetem načinu s shranjevanjem sprememb."
      }
    }
  },
  prompts: {
    delete: {
      title: "Izbris vprašalnika {title}?",
      message: "Ste prepričani da želite izbrisati vprašalnik {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili vprašalnik {title}",
    updated: "Uspešno ste posodobili vprašalnik {title}",
    deleted: "Uspešno ste izbrisali vprašalnik {title}",
    not_found: "Vprašalnik ni bil najden"
  }
}
