export default {
  views: {
    list: {
      header: {
        title: "Referenti in projekti",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj"
      },
      table: {
        name: "Ime in priimek",
        project: "Projekt",
        referent: "Referent",
        options: "Možnosti"
      }
    }
  },
  popup: {
    title: "Nov zaposleni",
    fields: {
      employee: {
        label: "Zaposleni",
        search: {
          not_found: "Zaposleni ne obstaja"
        }
      },
      name: {
        label: "Ime in priimek zaposlenega",
        placeholder: "Vpišite ime in priimek zaposlenega"
      },
      oppis_referent: {
        label: "Referent zaposlenega iz OpPISa",
        placeholder: "Vpišite referent zaposlenega iz OpPISa"
      },
      oppis_project: {
        label: "Projekt zaposlenega iz OpPISa",
        placeholder: "Vpišite projekt zaposlenega iz OpPISa"
      }
    },
    add_button: "Shrani",
    update_button: "Shrani"
  },
  prompts: {
    delete: {
      title: "Izbris OpPIS podatkov zaposlenega {name}",
      message: "Ste prepričani da želite izbrisati OpPIS podatke zaposlenega {name}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili zaposlenega {name}",
    updated: "Uspešno ste posodobili zaposlenega {name}",
    deleted: "Uspešno ste izbrisali OpPIS podatke zaposlenega {name}",
    not_found: "Zaposlen ni bil najden"
  }
}
