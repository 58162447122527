export default {
  views: {
    list: {
      header: {
        title: "Custom Entities",
        search_placeholder: "Search ...",
        add_button: "Add custom entity"
      },
      table: {
        title: "Title",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active"
      }
    },
    details: {
      header: {
        back_button: "Custom Entities list",
        edit_button: "Edit custom entity",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} custom entity here"
        }
      },
      main: {
        active: "Active",
        inactive: "Inactive",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        }
      }
    },
    edit: {
      header: {
        details_back_button: "Back to details",
        list_back_button: "Custom entities list",
        title: "New Custom Entity",
        save_button: "Save custom entity",
        add_button: "Add custom entity",
        remove_button: "Delete"
      }
    }
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Custom entity {title} created",
    updated: "Custom entity {title} updated",
    deleted: "Custom entity {title} was deleted",
    not_found: "Unable to find specified custom entity"
  }
}
