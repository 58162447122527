export default {
  install (Vue, options) {
    Vue.mixin({
      computed: {
        id () {
          return this.$route.params.id
        }
      }
    })
  }
}
