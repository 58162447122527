export default {
  settings: {
    user_settings: {
      headline: {
        basic: "Osebni podatki",
        position: "Podatki pozicije",
        contact: "Kontaktni podatki"
      },
      headline_description: "Uporabniške nastavitve",
      fields: {
        name: {
          label: "Ime in priimek"
        },
        email: {
          label: "Elektronski naslov",
          placeholder: "info@email.com"
        },
        username: {
          label: "Uporabniško ime"
        },
        address: {
          label: "Hišni naslov",
          placeholder: "Naslov in hišna številka"
        },
        postal: {
          label: "Poštni naslov",
          placeholder: "Poštna številka in kraj pošte"
        },
        country: {
          label: "Država"
        },
        phone: {
          label: "Telefonska številka",
          placeholder: "+386 (00) 000 000"
        },
        position_title: {
          label: "Naziv pozicije"
        },
        position_department: {
          label: "Naziv oddelka"
        }
      },
      button: {
        save: "Shrani podatke"
      }
    }
  }
}
