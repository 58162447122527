import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/offers",
    name: "Offers",
    meta: { title: "Ponudbe" },
    component: () => import(/* webpackChunkName: "Offers List" */ "@/Modules/Offer/Views/List/List"),
    children: [
      {
        path: "new",
        name: "New Offer",
        meta: { title: "Nova ponudba" }
      },
      {
        path: "edit/:id",
        name: "Edit Offer (List)"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/offers/details/:id",
    name: "Offer Details",
    component: () => import(/* webpackChunkName: "Offer Details" */ "@/Modules/Offer/Views/Details/Details"),
    children: [
      {
        path: "edit",
        name: "Edit Offer (Details)"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
