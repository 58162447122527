export default {
  views: {
    list: {
      header: {
        title: "Delovne izkušnje",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj"
      },
      table: {
        company_name: "Podjetje",
        job_title: "Delovno mesto",
        current_employer: "Trenutna zaposlitev",
        work_start_date: "Začetek",
        work_end_date: "Konec",
        experience_in_company: "Izkušnje v podjetju"
      }
    }
  },
  popup: {
    title: "Delovna izkušnja",
    fields: {
      company: "Podjetje",
      company_name: "Naziv podjetja",
      job_title: "Naziv delovnega mesta",
      current_employer: "Trenutna zaposlitev",
      current_employer_description: "To je kandidatova trenutna zaposlitev",
      work_start_date: "Začetek dela",
      work_end_date: "Konec dela",
      experience_in_company: "Izkušnje v podjetju",
      company_not_found: "Podjetje ne obstaja",
      no_company: "Brez podjetja"
    },
    add_button: "Shrani",
    update_button: "Shrani"
  },
  prompts: {
    delete: {
      title: "Izbriši delovno izkušnjo",
      message: "Ste prepričani, da želite izbrisati delovno izkušnjo?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili delovno izkušnjo",
    updated: "Uspešno ste posodobili delovno izkušnjo",
    deleted: "Uspešno ste izbrisali delovno izkušnjo",
    not_found: "Delovna izkušnja ni bila najdena"
  }
}
