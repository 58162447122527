export default {
  views: {
    list: {
      header: {
        title: "Zaposleni",
        search_placeholder: "Iskanje ...",
        add_button: "Nov zaposleni"
      },
      table: {
        name: "Ime in priimek",
        position_department: "Oddelek",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        active: "Aktiven",
        options: "Možnosti"
      }
    },
    details: {
      header: {
        back_button: "Na listo zaposlenih",
        edit_button: "Uredi zaposlenega",
        toggle: {
          title: "Aktiven",
          activate: "aktivirate",
          deactivate: "deaktivirate",
          description: "Zaposlenega lahko {action} tukaj"
        }
      },
      basic: {
        contact_info: "Kontaktni podatki",
        address: "Naslovni podatki",
        postal: "Poštni podatki",
        phone: "Telefonska številka",
        country: "Država",
        referent: "OpPIS referent",
        project: "OpPIS projekt"
      },
      projects: {
        headline: "Projekti",
        headline_description: "Vsi projekti zaposlenega v kronološkem zaporedju. Zaposleni še nima projektov.",
        table: {
          title: "Naziv projekta",
          responsible: "Odgovorna oseba",
          updated_at: "Posodobljeno"
        }
      }
    }
  },
  popup: {
    title: "Nov zaposleni",
    fields: {
      name: {
        label: "Ime in priimek zaposlenega",
        placeholder: "Vpišite ime in priimek zaposlenega"
      },
      oppis_referent: {
        label: "Referent zaposlenega iz OpPISa",
        placeholder: "Vpišite referent zaposlenega iz OpPISa"
      },
      oppis_project: {
        label: "Projekt zaposlenega iz OpPISa",
        placeholder: "Vpišite projekt zaposlenega iz OpPISa"
      },
      address: {
        label: "Hišni naslov",
        placeholder: "Vpišite naslov in hišno številko"
      },
      postal: {
        label: "Poštni naslov",
        placeholder: "Vpišite poštno številko in kraj"
      },
      country: {
        label: "Država",
        placeholder: "Vpišite državo"
      },
      phone: {
        label: "Telefonska številka",
        placeholder: "Vpišite telefonsko številko"
      },
      position_title: {
        label: "Naziv pozicije",
        placeholder: "Vpišite naziv pozicije"
      },
      position_department: {
        label: "Naziv oddelka",
        placeholder: "Vpišite naziv oddelka"
      },
      email: {
        label: "Elektronski naslov",
        placeholder: "Vpišite elektronski naslov"
      }
    },
    add_button: "Shrani zaposlenega",
    update_button: "Shrani zaposlenega"
  },
  prompts: {
    delete: {
      title: "Izbris zaposlenega {name}",
      message: "Ste prepričani da želite izbrisati zaposlenega {name}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili zaposlenega {name}",
    updated: "Uspešno ste posodobili zaposlenega {name}",
    deleted: "Uspešno ste izbrisali zaposlenega {name}",
    not_found: "Zaposleni ni bil najden"
  }
}
