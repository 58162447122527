import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/competences",
    name: "Competences",
    meta: { title: "Kompetence" },
    component: () => import(/* webpackChunkName: "Competences List" */ "@/Modules/Competence/Views/List"),
    children: [
      {
        path: "new",
        name: "New Competence",
        meta: { title: "Nova kompetenca" }
      },
      {
        path: "edit/:id",
        name: "Edit Competence (List)"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
