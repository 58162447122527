export default {
  data () {
    return {
      title: { main: "Competo DIGI", view: null, full: null }
    }
  },
  computed: {
    routeTitle () {
      return this.$route.meta ? this.$route.meta.title : ""
    }
  },
  watch: {
    routeTitle (to) {
      if (to) this.setTitle(to)
    }
  },
  methods: {
    setTitle (title) {
      this.title.view = title
      this.title.full = (title ? title + " | " : "") + this.title.main
      document.title = this.title.full
    }
  }
}
