import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/settings",
    name: "settings",
    meta: { title: "Nastavitve" },
    component: () => import(/* webpackChunkName: "Settings" */ "@/Modules/Auth/Settings/Views/Details.vue"),
    beforeEnter: ifAuthenticated
  }
]
