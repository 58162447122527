import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/assessment-center",
    name: "Rating Centers",
    meta: { title: "Ocenjevanja" },
    component: () => import(/* webpackChunkName: "Rating Centers List" */ "@/Modules/RatingCenter/Views/List"),
    children: [
      {
        path: "new",
        name: "New Rating Center",
        meta: { title: "Novo ocenjevanje" }
      },
      {
        path: "edit/:id",
        name: "Edit Rating Center (List)"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/assessment-center/details/:id",
    name: "Rating Center Details",
    component: () => import(/* webpackChunkName: "Rating Center Details" */ "@/Modules/RatingCenter/Views/Details"),
    children: [
      {
        path: "edit",
        name: "Edit Rating Center (Details)"
      },
      {
        path: "users",
        name: "Edit Users Rating center (Details)"
      },
      {
        path: "competence/:id_competence",
        name: "Rating Center Competence (Details)"
      },
      {
        path: "competences/add",
        name: "Rating Center Add Competence (Details)"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
