import LaravelResourceRepository from "@/providers/api/repositories/LaravelResourceRepository"

export default class Company extends LaravelResourceRepository {
  route = "chat"
  namespace = "chat"
  identifier = "id"

  state = {
    listDefaultParams: {
      with: []
    },
    detailsDefaultParams: {
      with: []
    }
  }

  actions = {
    getContacts: async ({ commit }, params) => {
      let response = await this.request({ namespace: "getContacts" }).post("/chat/contacts", params)

      return response
    },
    getUserMessages: async ({ commit }, params) => {
      let response = await this.request({ namespace: "getUserMessages" }).post("/chat/user-messages", params)

      return response
    },
    getTotalUnread: async ({ commit }, params) => {
      let response = await this.request({ namespace: "getTotalUnread" }).post("/chat/total-unread", params)

      return response
    },
    sendMessage: async ({ commit }, params) => {
      let response = await this.request({ namespace: "sendMessage" }).post("/chat/send-message", params)

      return response
    }
  }
}
