<template>
    <div class="chat-popup__container">
        <UikPopup
            v-if="visible"
            visible
            :max-width="!showOnlyForUserId ? 1100 : 685"
            class="chat-popup"
            @close="close()"
            @created="created()"
            @beforeDestroy="beforeDestroy()"
        >
            <UikTopBarTitle is="h2">{{ t('title') }}</UikTopBarTitle>
            <div class="chat__wrapper">
                <div class="chat__container">
                    <div v-if="!showOnlyForUserId" class="contacts__wrapper">
                        <div class="select-project">
                            <ResourceSelect
                                v-model="project_id"
                                :resource="$api.ProjectChat"
                                :options="formatedProjects"
                                :remaps="{ key: 'id', title: 'title' }"
                                sort="-created_at"
                                :filter="{ where: ['status', 'open'] }"
                                :nullOption="t('project.null')"
                                :label="t('project.label')"
                                :search-columns="['projects->title', 'company.title', 'projects->id', 'responsiblePerson.name']"
                                :no-results="{
                                    text: this.t('project.not_found'),
                                }"
                                @change="filterContactsByProject()"
                            />
                            <div class="redirect">
                                <UikTooltip :text="t('project.redirect')">
                                    <router-link v-if="selectedProject" :to="'/projects/details/' + selectedProject.uid">
                                        <button class="redirect-button" @click="close()">
                                            <UikIcon :icon="['fas', 'location-arrow']"/>
                                        </button>
                                    </router-link>
                                </UikTooltip>
                            </div>
                        </div>
                        <div class="contacts">
                            <div
                                v-for="user in contacts"
                                :key="'user-' + user.id"
                                class="contact"
                                :class="{'contact--selected': selectedContactId === user.id}"
                                @click="selectContact(user.id)"
                            >
                                <UikTooltip class="projects" :text="getProjectsList(user)">
                                    <UikAvatar
                                        :name="user.name ? user.name : ''"
                                        :title="user.name ? user.name : ''"
                                        color="primary"
                                        :imageUrl="user.avatar_url ? user.avatar_url : ''"
                                        :textBottom="user.last_msg ? user.last_msg : ''"
                                    />
                                </UikTooltip>
                                <div class="indicators">
                                    <div v-if="user.last_msg_date" class="date">{{ getDisplayDate(user.last_msg_date) }}</div>
                                    <div v-if="user.unread_count" class="unread-number">{{ getUnreadMsgs(user.unread_count) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat">
                        <div class="user-details-header">
                            <div class="profile">
                                <UikAvatar
                                    v-if="selectedContact"
                                    :name="selectedContact && selectedContact.name ? selectedContact.name : ''"
                                    :title="selectedContact && selectedContact.name ? selectedContact.name : ''"
                                    :textBottom="getCandidate(selectedContact) && getCandidate(selectedContact).phone ? getCandidate(selectedContact).phone : '' "
                                    color="primary"
                                    :imageUrl="selectedContact && selectedContact.avatar_url ? selectedContact.avatar_url : ''"
                                />
                                <UikTooltip
                                    v-if="invalidNumber"
                                    :text="t('no_number')"
                                    :class="'fa-2x tooltip-number'"
                                >
                                    <UikIcon :icon="['fas', 'circle-exclamation']"/>
                                </UikTooltip>
                            </div>
                            <div class="redirect">
                                <UikTooltip :text="t('show_candidate')">
                                    <router-link v-if="getCandidate(selectedContact)" :to="'/candidates/details/' + getCandidate(selectedContact).uid">
                                        <button class="redirect-button" @click="close()">
                                            <UikIcon :icon="['fas', 'location-arrow']"/>
                                        </button>
                                    </router-link>
                                </UikTooltip>
                            </div>
                        </div>
                        <div class="messages">
                            <div
                                ref="history"
                                class="history"
                                :class="{
                                    'history--msgs-loading': msgsLoading
                                }"
                            >
                                <div v-if="msgsLoading" class="msgs-loading-container">
                                    <UikLoader
                                        :size="2"
                                        :color="'black'"
                                        :type="'spinner-fade'"
                                    />
                                    <div class="text">{{ t('loading_msgs') }}</div>
                                </div>

                                <EmptyListNotice
                                    v-else-if="messages.length === 0"
                                    :text="t('empty_history')"
                                />

                                <div
                                    v-for="(msg, index) in messages"
                                    v-else
                                    :key="'msg-' + msg.id"
                                    class="msg__wrapper"
                                >
                                    <div v-if="getDateBreak(index)" class="date-break">{{ getDateBreak(index) }}</div>

                                    <div
                                        class="msg__container"
                                        :class="{
                                            'msg__container--incoming': msg.data && msg.data.direction === 'incoming',
                                            'msg__container--outgoing': msg.data && msg.data.direction === 'outgoing'
                                        }"
                                    >
                                        <div
                                            class="msg"
                                            :title="msg.data && msg.data.direction === 'incoming' ? (selectedContact && selectedContact.name ? selectedContact.name : '') : (msg.data && msg.data.sent_by ? msg.data.sent_by : '')"
                                            v-html="nlbr(msg.data && msg.data.plain_content ? msg.data.plain_content : '')"
                                        />
                                        <div class="date">
                                            {{ getDisplayHour(msg.created_at) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="new-message">
                                <EditorComponent ref="editor" class="new-message__input" :editor="editor"/>
                                <UikButton
                                    v-if="userCan('Chat/edit')"
                                    iconOnly
                                    :disabled="!isValid || $api.Chat.GetLoader('sendMessage')"
                                    :is-loading="$api.Chat.GetLoader('sendMessage')"
                                    primary
                                    class="send-button"
                                    :title="t('send')"
                                    @click="confirm()"
                                >
                                    <UikIcon :icon="['fas', 'paper-plane-top']"/>
                                </UiKButton>
                                <UikTableActions v-if="selectedContact" class="options-dropdown">
                                    <UikDropdownItem @click.native="notificationSelectPopup = true">
                                        <UikIcon :icon="['fas', 'bell-exclamation']"/>{{ t("fill_from_template") }}
                                    </UikDropdownItem>
                                </UikTableActions>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UikPopup>
        <NotificationSelectPopup
            v-if="notificationSelectPopup"
            :visible="notificationSelectPopup"
            @template="selectTemplate($event)"
            @close="notificationSelectPopup = false"
        />
        <UikOverlay :visible="visible" class="chat-overlay" @close="close()"/>
    </div>
</template>

<script>
import Chat from "@/Modules/Chat/Resources/Chat"
import Project from "@/Modules/Project/Resources/Project"

import NotificationSelectPopup from "./NotificationSelectPopup.vue"
import EditorComponent from "@/Modules/NotificationTemplate/Views/Edit/Components/Editor"
import EmptyListNotice from "@/Components/EmptyListNotice"

import { Editor } from "@tiptap/vue-2"
import StarterKit from "@tiptap/starter-kit"
import Placeholder from "@tiptap/extension-placeholder"

export default {
  langKey: "Chat.popup",
  resources: { Chat, ProjectChat: Project },
  components: { EditorComponent, NotificationSelectPopup, EmptyListNotice },
  props: {
    visible: { type: Boolean, default: false },
    showOnlyForUserId: { type: Number, default: null }
  },
  data () {
    return {
      form: {},
      editor: new Editor({
        extensions: [
          StarterKit,
          Placeholder.configure({
            placeholder: this.t("msg_placeholder")
          })
        ]
      }),
      contacts: [],
      messages: [],
      selectedContactId: null,
      isChatLoaded: false,
      msgsLoading: false,
      notificationSelectPopup: false,
      project_id: null
    }
  },
  computed: {
    isValid () {
      return !this.editor.isEmpty && this.selectedContactId && !this.invalidNumber
    },
    selectedContact () {
      if (this.contacts.length > 0) {
        return this.contacts.find(item => item.id === this.selectedContactId)
      }

      return null
    },
    invalidNumber () {
      if (!this.selectedContact || !this.getCandidate(this.selectedContact)) {
        return false
      }

      if (!this.getCandidate(this.selectedContact).phone) {
        return true
      }

      return false
    },
    formatedProjects () {
      return this.$api.ProjectChat.list.map(el => {
        return {
          id: el.id,
          title: el.title + (el.responsible_person && el.responsible_person.name ? " (" + el.responsible_person.name + ")" : "")
        }
      })
    },
    selectedProject () {
      return this.project_id && this.$api.ProjectChat.list.length === 1 ? this.$api.ProjectChat.list[0] : null
    },
    user () {
      return this.$api.Auth.user
    }
  },
  methods: {
    async getContacts () {
      let payload = {}

      if (this.showOnlyForUserId) {
        payload.userId = this.showOnlyForUserId
      }

      if (this.project_id) {
        payload.projectId = this.project_id
      }

      let res = await this.$api.Chat.getContacts(payload)

      this.contacts.splice(0)

      if (res.status === 200 && res.data) {
        res.data.forEach(item => {
          this.contacts.push(item)
        })
      }
    },

    async loadUserMessages (userId) {
      await this.getUserMessages(userId)

      this.scrollToBottom()
    },

    async getUserMessages (userId) {
      this.msgsLoading = true

      let res = await this.$api.Chat.getUserMessages({ userId: userId })

      this.msgsLoading = false

      this.messages.splice(0)

      if (res.status === 200 && res.data) {
        res.data.forEach(item => {
          this.messages.push(item)
        })
      }

      return true
    },

    filterContactsByProject () {
      this.selectedContactId = null

      this.messages.splice(0)

      this.clearEditor()

      this.getContacts()
    },

    async selectContact (userId) {
      if (this.selectedContactId === userId) {
        return
      }

      this.selectedContactId = userId

      this.loadUserMessages(userId)

      this.clearEditor()

      this.focusNewMessageInput()
    },

    scrollToBottom () {
      let element = this.$refs.history
      element.scrollTo({ top: element.scrollHeight })
    },

    focusNewMessageInput () {
      this.editor.commands.focus()
    },

    async confirm () {
      this.form.content_schema = {
        json: this.editor.getJSON(),
        html: this.editor.getHTML()
      }

      let payload = {
        userId: this.selectedContactId,
        form: this.form
      }

      if (this.selectedProject?.id) {
        payload.projectId = this.selectedProject.id
      }

      let res = await this.$api.Chat.sendMessage(payload)
      if (res.status === 200 && res.data) {
        await this.messages.push(res.data)

        this.scrollToBottom()

        this.clearEditor()

        this.getContacts()
      } else if (res.isError) {
        this.$notify.error(res.error.response.data.message)
      } else {
        this.$notify.error(this.t("error"))
      }
    },

    created () {
      this.getContacts()

      this.socketListener()

      this.form.content_schema = {
        json: this.editor.getJSON(),
        html: this.editor.getHTML()
      }

      if (this.showOnlyForUserId) {
        this.selectContact(this.showOnlyForUserId)
      }
    },

    beforeDestroy () {
      this.form = {}
      this.selectedContactId = null
      this.msgsLoading = false
      this.notificationSelectPopup = false
      this.contacts.splice(0)
      this.messages.splice(0)
      this.project_id = null

      window.Echo.channel("user").stopListening(".chat-updated")
    },

    clearEditor () {
      this.editor.commands.clearContent()
    },

    selectTemplate (template) {
      // Populate html / json
      if (this.form.content_schema?.html) {
        this.form.content_schema.html = this.parseText(template.content_schema.html)
        this.editor.commands.setContent(this.form.content_schema.html)
        this.form.content_schema.json = this.editor.getJSON()
      }

      this.notificationSelectPopup = false
    },

    parseText (text) {
      if (!text) {
        return text
      }

      try {
        // Functions
        let matches = text.match(/\{{ Function(.*?)\}}/g)
        if (matches && matches.length) {
          for (let i = 0; i < matches.length; i++) {
            const property = matches[i].replace("{{", "").replace("}}", "").trim()
            text = text.replace(matches[i], this.getParsedResult(property))
          }
        }

        // User
        let matches2 = text.match(/\{{ User(.*?)\}}/g)
        if (matches2 && matches2.length) {
          for (let i = 0; i < matches2.length; i++) {
            const property = matches2[i].replace("{{", "").replace("}}", "").trim()
            text = text.replace(matches2[i], this.getParsedResult(property))
          }
        }
      } catch (e) { console.log(e) }
      return text
    },

    getParsedResult (property) {
      let method
      if (property.startsWith("User")) {
        method = property.replace("User.", "")
      } else {
        method = property.replace("Function.", "")
      }

      if (!method) return ""

      if (["getJobName", "getName", "getPositionTitle", "name"].includes(method)) {
        // eslint-disable-next-line no-eval
        return eval(`this.${method}()`) || ""
      }

      return ""
    },

    getJobName () {
      if (this.selectedProject?.ad?.name) {
        return this.selectedProject.ad.name
      } else if (this.selectedProject?.title) {
        return this.selectedProject.title
      }

      return ""
    },

    getName () {
      // eslint-disable-next-line camelcase
      if (this.selectedProject?.responsible_person) {
        return this.selectedProject.responsible_person.name ?? ""
      } else if (this.user) {
        return this.user.name ?? ""
      }

      return ""
    },

    getPositionTitle () {
      // eslint-disable-next-line camelcase
      if (this.selectedProject?.responsible_person) {
        if (this.selectedProject.responsible_person.relations && this.selectedProject.responsible_person.relations.length > 0) {
          let model = null
          this.selectedProject.responsible_person.relations.forEach(relation => {
            if (relation.type === "employee" && relation.model?.id) {
              model = relation.model
            }
          })

          // eslint-disable-next-line camelcase
          return model?.position_title ? model.position_title : ""
        }
      } else if (this.user) {
        // eslint-disable-next-line camelcase
        return this.user.employee?.position_title ? this.user.employee.position_title : ""
      }

      return ""
    },

    name () {
      return this.selectedContact && this.selectedContact.name ? this.selectedContact.name : ""
    },

    getProjectsList (user) {
      let list = user.testing_user.filter(testing => testing.testingall && testing.testingall.projectall != null)
      list.sort((a, b) => b.testingall.projectall.status.localeCompare(a.testingall.projectall.status) || b.testingall.projectall.id - a.testingall.projectall.id)

      let msg = ""

      if (list.length > 0) {
        msg = this.t("tooltip_projects.participated") + "<br>"

        list.forEach(item => {
          // eslint-disable-next-line camelcase
          msg += "• " + (item?.testingall?.projectall?.title ?? "") + (item?.testingall?.projectall?.responsible_person?.name ? " (" + item.testingall.projectall.responsible_person.name + ")" : "") + "<br>"
        })
      } else {
        msg = this.t("tooltip_projects.not_participated")
      }

      return msg
    },

    async socketListener () {
      if (!window.Echo) return

      window.Echo.channel("user").listen(".chat-updated", event => {
        if (event?.message?.userId && event.message.userId === this.selectedContactId) {
          this.loadUserMessages(event.message.userId)
        } else {
          this.getContacts()
        }
      })
    },

    getDisplayDate (date) {
      if (this.moment(date).isSame(this.moment(), "day")) {
        return this.moment(date).format("HH:mm")
      }

      return this.moment(date).format("DD.MM.YYYY")
    },

    getDisplayHour (date) {
      return this.moment(date).format("HH:mm")
    },

    getUnreadMsgs (number) {
      if (number && number > 99) {
        return "99+"
      }

      return number
    },

    getDateBreak (index) {
      // Always show date for first message
      if (index === 0 && this.messages[index]) {
        return this.moment(this.messages[index].created_at).format("DD.MM.YYYY")
      }

      if (!this.messages[index] || !this.messages[index - 1]) {
        return null
      }

      let prevMsg = this.messages[index - 1]
      let thisMsg = this.messages[index]

      // Don't return date break when this and prev msgs' dates are the same
      if (this.moment(prevMsg.created_at).isSame(this.moment(thisMsg.created_at), "day")) {
        return null
      }

      return this.moment(thisMsg.created_at).format("DD.MM.YYYY")
    },

    getCandidate (user) {
      let model = null

      if (user?.relations && user.relations.length > 0) {
        user.relations.forEach(relation => {
          if (relation.type === "candidate" && relation.model?.id) {
            model = relation.model
          }
        })
      }

      return model
    },

    nlbr (content) {
      if (/\n/g.test(content)) {
        return content.replace(/\n/g, "<br/>")
      }
      return content
    },

    close () {
      this.$emit("close")
    }
  }
}
</script>

<style lang="scss">
.chat-popup__container {
  .chat-popup {
    z-index: 2020;

    .uik-popup__content {
      // height: 100vh;
    }
  }
  .chat-overlay {
    z-index: 2015 !important;
  }
  .chat__wrapper {
    margin-top: 0;
    background: $bg;

    .chat__container {
      display: flex;
      flex-direction: row;
      padding: 15px;

      .chat {
        max-height: calc(100vh - 91px);
        height: 100%;
        overflow: auto;
        background: white;
      }

      .contacts__wrapper {
        max-height: calc(100vh - 91px);
        height: 100%;
        max-width: 400px;
        width: 100%;
        margin-right: 15px;

        .select-project {
          height: 90px;
          padding: 10px;
          margin-bottom: 10px;
          border-radius: 10px;
          border: 1px solid $borderColorDarker;
          box-shadow: $boxShadow;
          background: white;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          .uik-select__wrapper {
            width: 100%;
            max-width: 335px;
          }
          .redirect {
            margin-left: 10px;
          }
        }
      }

      .contacts {
        height: calc(100vh - 191px);
        overflow: auto;
        background: white;
        max-width: 400px;
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid $borderColorDarker;
        box-shadow: $boxShadow;

        .contact {
          padding: 15px 10px;
          border-radius: 10px;
          user-select: none;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .uik-avatar__wrapper {
            max-width: 250px;
            .uik-avatar__info {
              .uik-avatar__name {
                font-weight: bold;
              }

              .uik-avatar__textBottom {
                white-space: nowrap;
              }
            }
          }

          &:hover {
            background: rgba($borderColor, 0.5);
          }

          &--selected, &--selected:hover, &:active {
            background: $borderColor;
          }

          .indicators {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .date {
              font-size: 0.75rem;
              line-height: 16px;
              color: $textSecondary;
            }

            .unread-number {
              display: flex;
              justify-content: center;
              text-align: center;
              color: white;
              background: $error;
              min-width: 18px;
              line-height: 18px;
              padding: 0px 2px;
              border-radius: 99px;
              font-size: 10px;
              font-weight: bold;
            }
          }
        }
      }

      .chat {
        width: 100%;
        height: 100%;
        border-radius: 20px 20px 0px 0px;
        background: transparent;

        .user-details-header {
          background: darken($bg, 1%);
          padding: 15px;
          border: solid 1px $borderColorDarker;
          border-bottom: none;
          border-radius: 20px 20px 0px 0px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          height: 69px;

          .profile {
            display: flex;
            flex-direction: row;
            align-items: center;

            .uik-avatar__wrapper {
              .uik-avatar__info {
                .uik-avatar__name {
                  font-weight: bold;
                  font-size: 1rem;
                }
              }
            }

            .tooltip-number {
              margin-left: 20px;
              svg {
                color: $error;
              }
              .uik-tooltip__tooltip-container {
                .uik-tooltip__tooltip {
                  top: 150px;
                  &::after {
                    content: unset;
                  }
                }
              }
            }
          }
        }

        .messages {
          display: flex;
          flex-direction: column;

          .history {
            height: calc(100vh - 278px);
            min-height: 120px;
            overflow: auto;
            border: 1px solid $borderColorDarker;
            margin-bottom: 10px;
            border-radius: 0px 0px 20px 20px;
            display: flex;
            flex-direction: column;
            background: white;
            padding: 10px;
            &--msgs-loading {
              justify-content: center;
              align-items: center;

              .msgs-loading-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .text {
                  margin-top: 50px;
                  font-size: 16px;
                  line-height: 16px;
                  color: $textMain;
                  font-weight: 500;
                  user-select: none;
                }
              }
            }

            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
              display: none;
            }

            /* Hide scrollbar for IE, Edge and Firefox */
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */

            .msg__wrapper {
              width: 100;
              display: flex;
              flex-direction: column;

              .date-break {
                align-self: center;
                font-size: 14px;
                margin: 5px 0px;
                color: $textSecondary;
              }

              .msg__container {
                margin: 5px;
                max-width: 40%;

                &--incoming {
                  align-self: flex-start;
                  .msg {
                    background: $borderColor;
                  }
                }

                &--outgoing {
                  align-self: flex-end;
                  .msg {
                    background: lighten($lime, 15%);
                  }
                }

                .msg {
                  color: $textMain;
                  padding: 15px;
                  border-radius: 15px;
                  border: 1px solid $borderColorDarker;
                  word-wrap: break-word;
                }

                .date {
                  color: $textSecondary;
                  font-size: 14px;
                  text-align: end;
                  margin-top: 5px;
                  margin-right: 10px;
                }
              }

            }
          }

          .new-message {
            display: flex;
            flex-direction: row;
            align-items: center;
            background: $bg;
            position: relative;
            border-radius: 25px;
            .new-message__input {
              width: 100%;
              border-radius: 25px;
              .ProseMirror {
                height: 108px;
                border-radius: 25px;
                margin-top: 0;
                padding: 20px;
                padding-right: 75px;
                color: $textMain;
                p {
                  color: $textMain;
                  font-size: 1rem;
                }

                /* Hide scrollbar for Chrome, Safari and Opera */
                &::-webkit-scrollbar {
                    display: none;
                }

                /* Hide scrollbar for IE, Edge and Firefox */
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */

                p.is-editor-empty:first-child::before {
                  content: attr(data-placeholder);
                  float: left;
                  color: $textSecondary;
                  pointer-events: none;
                  height: 0;
                }
              }
            }

            .send-button {
              position: absolute;
              right: 0px;
              margin: 0px 20px;
              width: 35px;
              height: 35px;
              border-radius: 50% !important;
              cursor: pointer;
            }

            .options-dropdown {
              position: absolute;
              right: 5px;
              margin: 0px 20px;
              top: 7px;

              .uik-btn__iconOnly {
                width: 25px !important;
                height: 15px !important;
              }
            }
          }
        }
      }

      .redirect {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .redirect-button {
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          background: transparent;
          outline: none;
          border: none;
          width: 35px;
          height: 35px;
          color: $textMain;
          transition: all 0.2s;
          border-radius: 50%;
          &:hover {
            cursor: pointer;
            background: darken($borderColorDarker, 2%);
            color: $primary;
          }
          &:active {
            background: $borderColorDarker;
          }
        }
      }
    }
  }
}
</style>
