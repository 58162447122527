import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/testings",
    name: "Testings",
    meta: { title: "Testiranja" },
    component: () => import(/* webpackChunkName: "Testings List" */ "@/Modules/Testing/Views/List"),
    children: [
      {
        path: "new",
        name: "New Testing",
        meta: { title: "Novo testiranje" }
      },
      {
        path: "edit/:id",
        name: "Edit Testing (List)"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/testings/details/:id",
    name: "Testing Details",
    component: () => import(/* webpackChunkName: "Testing Details" */ "@/Modules/Testing/Views/Details"),
    children: [
      {
        path: "edit",
        name: "Edit Testing (Details)"
      },
      {
        path: "users",
        name: "Edit Users Testing (Details)"
      },
      {
        path: "participants/:id_user",
        name: "Testing User (Details)"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
