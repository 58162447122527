export default {
  views: {
    list: {
      header: {
        title: "Fokusne skupine",
        title_templates: "Predloge fokusnih skupin",
        search_placeholder: "Iskanje ...",
        add_button: "Nova fokusna skupina",
        add_button_template: "Nova predloga",
        toggle: {
          label: "Prikaži predloge",
          description: "Prikazujem samo predloge"
        }
      },
      table: {
        title: "Naziv",
        type: "Tip",
        responsible: "Odgovorna oseba",
        active: "Aktivna",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        options: "Možnosti",
        created_by: "Avtor"
      },
      type_options: {
        climate: "Klima",
        culture: "Kultura",
        commitment: "Zavzetost",
        "360": "360"
      }
    },
    edit: {
      title: "Nova fokusna skupina",
      back_button: "Na listo fokusnih skupin",
      tabs: {
        properties: "Lastnosti",
        groups: "Skupine",
        files: "Datoteke"
      },
      footer: {
        add_button: "Dodaj fokusno skupino",
        save_button: "Shrani fokusno skupino",
        saved: "Shranjeno ",
        not_saved: "Fokusna skupina še ni bila shranjena."
      },
      properties: {
        title: "Naziv",
        description: "Opis skupine",
        type: "Tip",
        participants: "Osebe v skupini",
        responsible: "Odgovorna oseba",
        company: "Podjetje",
        user_not_found: "Oseba ne obstaja",
        company_not_found: "Podjetje ne obstaja",
        new_person: {
          label: "Osebe v skupini",
          placeholder: "Vnesite novo osebo"
        },
        type_options: {
          climate: "Klima",
          culture: "Kultura",
          commitment: "Zavzetost",
          "360": "360"
        }
      },
      groups_template: {
        all: "Vse",
        questions: "Vprašanja"
      },
      groups: {
        table: {
          selected: "Izbrana",
          title: "Naziv skupine",
          remove: "Izbriši"
        },
        note: "Za dodajanje odgovorov je potrebno izbrati skupino. Izbrana skupina je prikazana v urejevalniku na desni strani.",
        new_group: {
          label: "Nova skupina",
          placeholder: "Vnesite novo skupino"
        },
        remove_group: {
          prompt: {
            title: "Izbris skupine?",
            message: "Z izbrisom skupine bodo izbrisani tudi odgovori vezani na to skupino. Ste prepričani da želite izbrisati skupino?",
            confirm: "Izbriši",
            cancel: "Prekliči"
          }
        }
      }
    }
  },
  editor: {
    shown_group: "Prikazano: ",
    new_section: "Nov sklop",
    add_question: "Dodaj vprašanje",
    add_answer: "Dodaj odgovor",
    remove_section: "Odstrani sklop",
    remove_questions_answers: "Odstrani vprašanje z odgovori",
    duplicate_questions_answers: "Podvoji vprašanje z odgovori",
    template: "Ustvari iz predloge",
    generate: "Generiraj poročilo",
    duplicate_content: "Podvoji vsebino",
    remove_content: "Odstrani vsebino",
    fields: {
      template: {
        label: "Ustvari iz predloge",
        not_found: "Predloga ne obstaja"
      }
    },
    fill_popup: {
      save_button: "Uporabi predlogo",
      note: "Opozorilo: Ob potrditvi uporabe predloge bo vsebina v predlogi prepisala morebitne zapiske v tej fokusni skupini (vprašanja in odgovore)."
    },
    write_answers: "Vpišite odgovor oz. tekst"
  },
  popup_template: {
    title: "Nova predloga",
    fields: {
      title: "Naziv",
      new_line: "Tukaj vpišite novo vprašanje"
    },
    add_button: "Shrani predlogo",
    update_button: "Shrani predlogo"
  },
  prompts: {
    delete: {
      title: "Izbris fokusne skupine {title}",
      message: "Ste prepričani da želite izbrisati fokusno skupino {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    },
    delete_template: {
      title: "Izbris predloge {title}",
      message: "Ste prepričani da želite izbrisati predlogo {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili fokusno skupino {title}",
    updated: "Uspešno ste posodobili fokusno skupino {title}",
    deleted: "Fokusna skupina {title} je bila izbrisana",
    not_found: "Fokusna skupina ni bila najdena"
  },
  notify_template: {
    stored: "Uspešno ste shranili predlogo {title}",
    updated: "Uspešno ste posodobili predlogo {title}",
    deleted: "Predloga {title} je bila izbrisana",
    not_found: "Predloga ni bila najdena"
  }
}
