import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/projects",
    name: "Projects",
    meta: { title: "Projekti" },
    component: () => import(/* webpackChunkName: "Projects List" */ "@/Modules/Project/Views/List"),
    children: [
      {
        path: "new",
        name: "New Project",
        meta: { title: "Nov projekt" }
      },
      {
        path: "edit/:id",
        name: "Edit Project (List)"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/projects/details/:id",
    name: "Project Details",
    component: () => import(/* webpackChunkName: "Project Details" */ "@/Modules/Project/Views/Details/VerticalPanel"),
    children: [
      {
        path: "edit",
        name: "Edit Project (Details)"
      },
      {
        path: "users",
        name: "Edit Users Project (Details)"
      },
      {
        path: "hiring-managers",
        name: "Edit Hiring Managers Project (Details)"
      },
      {
        path: "participants/:id_user",
        name: "Project User (Details)"
      },
      {
        path: "funnel/new",
        name: "New Project Funnel",
        meta: { title: "Nov podatek za lijak" }
      },
      {
        path: "funnel/edit/:id_funnel",
        name: "Edit Project Funnel"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
