export default {
  views: {
    list: {
      header: {
        title: "Meritve",
        search_placeholder: "Iskanje ...",
        add_button: "Nova meritev"
      },
      table: {
        title: "Naziv",
        status: "Stanje",
        responsible: "Odgovorna oseba",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        active: "Aktivna",
        options: "Možnosti",
        type: "Tip meritve"
      },
      type: {
        climate: "Klima",
        culture: "Kultura",
        commitment: "Zavzetost",
        "360": "360"
      }
    },
    details: {
      header: {
        back_button: "Na listo merjenj",
        edit_button: "Uredi meritev",
        toggle: {
          title: "Aktivna",
          activate: "aktivirate",
          deactivate: "deaktivirate",
          description: "Merjenje lahko {action} tukaj"
        }
      },
      basic: {
        project: "Projekt",
        measure_type: "Tip meritve",
        measure_360: "Meritev 360",
        questionnaire: "Pripet vprašalnik",
        document: "Pripet dokument",
        deadline_at: "Zaključek meritve",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        comment: "Opomba",
        type: {
          climate: "Klima",
          culture: "Kultura",
          commitment: "Zavzetost",
          "360": "360"
        }
      },
      measure_360_list: {
        headline: "Zaposleni",
        buttons: {
          edit_mode: {
            on: "Zaključi urejanje",
            off: "Uredi"
          },
          download_template: "Prenos uvozne datoteke",
          upload: "Uvoz podatkov",
          send_tests: {
            tooltip: "Pošljite dostopne podatke vsem zaposlenim.",
            text: "Pošiljanje testov"
          },
          show_results: "Prikaži rezultate",
          add_candidate: "Dodaj osebo",
          export: {
            tooltip: "Izvozite zaposlene s povezavo v .xlsx datoteki.",
            text: "Izvoz zaposlenih"
          },
          send_mail: {
            tooltip: "Kontaktom pošljite e-pošto s povezavo, do vprašalnika.",
            text: "Pošiljanje"
          }
        },
        table: {
          name: "Ime in priimek",
          email: "Elektronski naslov",
          status: "Status",
          options: "Možnosti",
          save_candidate: "Shrani osebo",
          remove_candidate: "Odstrani osebo",
          url: "Povezava",
          no_questionnaire: "Povezava ni na voljo, ker meritvi ni bil dodeljen vprašalnik.",
          department: "Oddelek",
          job: "Delovno mesto"
        },
        table_actions: {
          send_access: "Pošlji dostopne podatke",
          download_report: "Prenesi poročilo"
        },
        evaluators: {
          leader: "Vodje",
          coworker: "Sodelavci",
          employee: "Zaposleni"
        },
        status: {
          not_sent: "Ne poslano",
          open: "Poslano",
          in_progress: "V izvajanju",
          closed: "Zaprto"
        },
        methods: {
          error_message: "Nekaj je šlo po zlu, poskusite znova",
          save_candidate: {
            update: {
              success_message_evaluator: "Ocenjevalec je uspešno posodobljen",
              success_message_person: "Oseba je uspešno posodobljena"
            },
            add: {
              success_message_evaluator: "Ocenjevalec je uspešno dodan",
              success_message_person: "Oseba je uspešno dodana"
            }
          },
          remove_candidate: {
            prompt: {
              title: "Odstrani osebo",
              message: "Ste prepričani da želite odstraniti osebo ",
              confirm: "Izbriši",
              cancel: "Prekliči"
            },
            success_message: {
              part1: "Oseba ",
              part2: " je odstranjena"
            }
          },
          send_tests: {
            error_message: "Nekaj je šlo po zlu.",
            success_message: "Pošiljanje testov smo postavili v vrsto za pošiljanje."
          },
          send_mail: {
            error_message: "Nekaj je šlo po zlu.",
            success_message: "Pošiljanje elektronskih sporočil smo postavili v vrsto za pošiljanje."
          }
        },
        results: {
          main_title: "Rezultati meritve",
          title: "Naziv",
          closed: "Opravljeno",
          open: "Neopravljeno",
          export: "Izvoz rezultatov",
          average: "Povprečje",
          person: "Oseba",
          stat1: "Kontakti",
          stat2: "Rešeni vpr.",
          self_evaluation: {
            title: "Samoocena",
            span: "SO"
          },
          leader: {
            title: "Vodje",
            span: "V"
          },
          coworker: {
            title: "Sodelavci",
            span: "S"
          },
          employee: {
            title: "Zaposleni",
            span: "Z"
          },
          total: "Skupaj",
          ignore1: "Izključi",
          ignore2: "Vključi",
          analysis_toggle: {
            true: {
              label: "Vključena v analizo",
              description: "Oceno lahko izključite tukaj"
            },
            false: {
              label: "Izključena iz analize",
              description: "Oceno lahko vključite tukaj"
            }
          },
          warning: "Ocene vsebujejo enake rezultate"
        },
        impersonate: "Prijavi se v Portal kot uporabnik",
        impersonate_error_message: "Prijava ni mogoča, saj kandidat nima uporabniškega računa."
      },
      candidates_drawer: {
        title: "Kandidat",
        tabs: {
          overview: "Pregled"
        },
        overview: {
          headline: "Kandidat",
          buttons: {
            edit_mode: {
              on: "Zaključi urejanje",
              off: "Uredi"
            },
            send_tests: {
              tooltip: "Pošljite dostopne podatke.",
              text: "Pošiljanje testov"
            },
            save_candidate: "Shrani osebo",
            remove_candidate: "Odstrani osebo",
            add: "Dodaj",
            evaluator: {
              save: "Shrani ocenjevalca",
              remove: "Odstrani ocenjevalca"
            }
          },
          table: {
            name: "Ime in priimek",
            email: "Elektronski naslov",
            status: "Status",
            options: "Možnosti"
          },
          table_actions: {
            send_access: "Pošlji dostopne podatke",
            download_report: "Prenesi poročilo"
          },
          evaluators: {
            leader: "Vodje",
            coworker: "Sodelavci",
            employee: "Zaposleni"
          },
          status: {
            not_sent: "Ne poslano",
            open: "Poslano",
            in_progress: "V izvajanju",
            closed: "Zaprto"
          },
          methods: {
            error_message: "Nekaj je šlo po zlu, poskusite znova",
            save_candidate: {
              update: {
                success_message_evaluator: "Ocenjevalec je uspešno posodobljen",
                success_message_person: "Oseba je uspešno posodobljena"
              },
              add: {
                success_message_evaluator: "Ocenjevalec je uspešno dodan",
                success_message_person: "Oseba je uspešno dodana"
              }
            },
            remove_candidate: {
              prompt: {
                title: "Odstrani osebo",
                message: "Ste prepričani da želite odstraniti osebo ",
                confirm: "Izbriši",
                cancel: "Prekliči"
              },
              success_message: {
                part1: "Oseba ",
                part2: " je odstranjena"
              }
            },
            remove_evaluator: {
              prompt: {
                title: "Odstrani ocenjevalca",
                message: "Ste prepričani da želite odstraniti ocenjevalca ",
                confirm: "Izbriši",
                cancel: "Prekliči"
              },
              success_message: {
                part1: "Ocenjevalec ",
                part2: " je odstranjen"
              }
            },
            send_tests: {
              error_message: "Nekaj je šlo po zlu.",
              success_message: "Pošiljanje testov smo postavili v vrsto za pošiljanje."
            }
          },
          impersonate: "Prijavi se v Portal kot uporabnik",
          impersonate_error_message: "Prijava ni mogoča, saj kandidat nima uporabniškega računa."
        }
      },
      measure_structure: {
        "title": "Organigram podjetja",
        "description": "Struktura oddelkov in seznam zaposlenih v podjetju",
        "units": "Oddelki",
        "employees": "Zaposleni",
        "content": "Vsebina",
        "edit": "Uredi",
        "import_data": "Uvoz podatkov",
        "import_employees": "Uvoz zaposlenih",
        "import_results": "Uvoz rezultatov",
        "download_structure": "Prenos strukture",
        "new_subnode": "Podkategorija",
        "delete": "Izbriši",
        "close": "Zaključi urejanje",
        table_actions: {
          show_report: "Prikaži rezultate",
          download_report: "Prenesi poročilo",
          send_access: "Pošlji dostopne podatke"
        },
        company_employees: {
          employee: "Zaposleni",
          department_leader: "Vodja oddelka",
          input: {
            new_user: {
              placeholder: "Uporabniško ime ali elektronski naslov"
            },
            name: {
              placeholder: "Ime in priimek"
            },
            username: {
              placeholder: "Elektronski naslov"
            }
          },
          buttons: {
            save_user: "Shrani osebo",
            cancel: "Prekliči",
            add_user: "Dodaj osebo",
            remove_user: "Odstrani osebo",
            send_access: "Pošlji dostopne podatke"
          },
          methods: {
            multiple_drag_text: {
              user: "zaposlenih",
              user_two: "zaposlena",
              user_three: "zaposleni"
            },
            remove_user: {
              prompt: {
                title: "Odstrani osebo",
                message: "Ste prepričani, da želite odstraniti osebo ",
                confirm: "Odstrani",
                cancel: "Prekliči",
                success_message: {
                  part1: "Oseba ",
                  part2: " je bila odstranjena"
                }
              }
            },
            save_user: {
              success_message: "Uspešno ste dodali novo osebo.",
              error_message: "Pri shranjevanju je prišlo do napake."
            },
            send_access: {
              title: "Pošlji dostopne podatke",
              message: {
                part1: "Osebi ",
                part2: " boste preko elektronske pošte poslali podatke za dostop do meritve. Želite nadaljevati?"
              },
              confirm: "Pošlji",
              cancel: "Prekliči",
              success_message: "Podatki za dostop so bili poslani"
            }
          },
          status: {
            not_sent: "Ne poslano",
            open: "Poslano",
            in_progress: "V izvajanju",
            closed: "Zaprto"
          },
          impersonate: "Prijavi se v Portal kot uporabnik",
          impersonate_error_message: "Prijava ni mogoča, saj kandidat nima uporabniškega računa.",
          impersonate_error: "Pri prijavi je prišlo do napake."
        },
        tree_menu_node: {
          methods: {
            error_message: "Nekaj je šlo po zlu",
            download_report: "Prenos poročila",
            send_access: {
              prompt: {
                title: "Pošlji dostopne podatke",
                message: {
                  part1: "Zaposlenim v ",
                  part2: " boste preko elektronske pošte poslali podatke za dostop do meritve. Želite nadaljevati?"
                },
                confirm: "Pošlji",
                cancel: "Prekliči"
              },
              units_text: {
                first: "oddelku",
                second: "oddelkom",
                third: "oddelkih"
              },
              success_message: "Podatki za dostop poslani "
            }
          }
        },
        results: {
          title: "Rezultati meritve",
          unit: "Oddelek",
          employees: "Št. zaposlenih",
          closed: "Opravljeno",
          open: "Neopravljeno",
          toggle: {
            data: "Podatki",
            show_data: "Prikaži podatke kandidatov",
            answers: "Odgovori",
            show_answers: "Prikaži odgovore"
          },
          buttons: {
            export_excel: "Izvoz rezultatov",
            export_excel_ocai: "Izvoz rezultatov (OCAI)"
          },
          person: "Oseba",
          duration: "Čas reševanja",
          status: "Stanje",
          average: "M",
          sd: "SD",
          leader: "Vodja oddelka",
          tag: {
            closed: "Opravljeno",
            open: "Neopravljeno"
          },
          total: "Skupaj",
          gender: {
            male: "Moški",
            female: "Ženska"
          }
        }
      },
      contacts_drawer: {
        title: "Kandidat",
        tabs: {
          overview: "Pregled"
        },
        overview: {
          headline: "Kandidat",
          table_name: "Kontakti",
          buttons: {
            edit_mode: {
              on: "Zaključi urejanje",
              off: "Uredi"
            },
            save: "Shrani",
            remove: "Odstrani kontakt",
            add: "Dodaj",
            evaluator: {
              save: "Shrani ocenjevalca",
              remove: "Odstrani ocenjevalca"
            }
          },
          table: {
            email: "Elektronski naslov"
          },
          methods: {
            save: {
              success: "Kontakti so bili posodobljeni",
              error: "Nekaj je šlo po zlu, poskusite znova"
            }
          }
        }
      },
      measure_public: {
        headline: "Javna meritev",
        headline_desc: "Povezava na meritev",
        show_results_button: "Prikaži rezultate"
      },
      stats: {
        status: "Stanje meritve",
        questionnaires: "Število opravljenih vprašalnikov",
        measure_closed: "Merjenje zaključeno",
        finished: "Opravljeno",
        close_measure: {
          button: "Zaključi meritev",
          prompt: {
            title: "Zaključi meritev",
            message: "Ste prepričani, da želite meritev zaključiti?",
            confirm: "Zaključi",
            cancel: "Prekliči"
          },
          error_message: "Pri shranjevanju je prišlo do napake.",
          success_message: "Procedura za zaključevanje meritve se je pričela."
        }
      },
      users_popup: {
        title: "Upravljanje izvajalcev",
        search: "Iskanje ...",
        table: {
          name: "Ime in priimek",
          presence: "Prisotnost v meritvi"
        }
      },
      users: {
        responsible: "Odgovorna oseba",
        users: "Izvajalci",
        manage_users: "Upravljanje izvajalcev",
        empty_list: "Lista izvajalcev je prazna."
      }
    }
  },
  popup: {
    title: "Nova meritev",
    fields: {
      title: {
        label: "Naziv meritve",
        placeholder: "Vpišite naziv meritve"
      },
      questionnaire: {
        label: "Vprašalnik"
      },
      document: {
        label: "Dokument",
        search: {
          not_found: "Dokument ne obstaja"
        }
      },
      deadline: {
        headline: "Zaključek meritve",
        headline_description: "Meritev se bo samodejno zaključila ob nastavljenem datumu.",
        label: "Datum"
      },
      is_public: {
        label: "Odprta povezava",
        description1: "Ne zahteva prijave v portal.",
        description2: "Zahteva prijavo v portal."
      },
      customer_info: {
        label: "Izbrani podatki",
        headline: "Podatki o kandidatih",
        headline_description: "Izberite dodatne podatke kandidatov, ki jih želite prikazane v rezultatih meritve."
      },
      measure_360: {
        label: "Meritev 360",
        description: "Projektu nastavi lastnosti meritve 360."
      },
      comment: {
        label: "Opomba"
      },
      responsible: {
        label: "Odgovorna oseba",
        search: {
          not_found: "Oseba ne obstaja"
        }
      },
      company: {
        label: "Podjetje",
        search: {
          not_found: "Podjetje ne obstaja",
          add_button: "Dodaj podjetje"
        }
      },
      type: {
        label: "Tip meritve"
      }
    },
    type: {
      climate: "Klima",
      culture: "Kultura",
      commitment: "Zavzetost",
      "360": "360",
      ocai: "OCAI"
    },
    add_button: "Shrani meritev",
    update_button: "Shrani meritev"
  },
  prompts: {
    delete: {
      title: "Izbris meritve {title}",
      message: "Ste prepričani da želite izbrisati meritev {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili meritev {title}",
    updated: "Uspešno ste posodobili meritev {title}",
    deleted: "Uspešno ste izbrisali meritev {title}",
    not_found: "Meritev ni bila najdena"
  }
}
