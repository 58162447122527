export default {
  views: {
    details: {
      basic: {
        headline_desc: "Želimo vam upešen dan!"
      },
      projects: {
        headline: "Odgovornost projektov",
        headline_description: "Vsi projekti kamor ste navedeni kot uporabnik. Trenutno vaš račun še ni naveden v nobenem projektu.",
        table: {
          title: "Naziv projekta",
          responsible: "Odgovorna oseba",
          updated_at: "Posodobljeno"
        }
      },
      testings: {
        headline: "Odgovornost testiranj",
        headline_description: "Vsa testiranja kamor ste navedeni kot uporabnik. Trenutno vaš račun še ni naveden v nobenem testiranju.",
        table: {
          title: "Naziv testiranja",
          responsible: "Odgovorna oseba",
          updated_at: "Posodobljeno"
        }
      },
      measures: {
        headline: "Odgovornost meritev",
        headline_description: "Vse meritve kamor ste navedeni kot uporabnik. Trenutno vaš račun še ni naveden v nobeni meritvi.",
        table: {
          title: "Naziv meritve",
          responsible: "Odgovorna oseba",
          updated_at: "Posodobljeno"
        }
      },
      rating_centers: {
        headline: "Odgovornost ocenjevalnih centrov",
        headline_description: "Vsi ocenjevalni centri kamor ste navedeni kot uporabnik. Trenutno vaš račun še ni naveden v nobenem ocenjevalnem centru.",
        table: {
          title: "Naziv ocenjevalnega centra",
          responsible: "Odgovorna oseba",
          updated_at: "Posodobljeno"
        }
      },
      contracts: {
        headline: "Odgovornost pogodb",
        headline_description: "Vse pogodbe kamor ste navedeni kot uporabnik. Trenutno vaš račun še ni naveden v nobeni pogodbi.",
        table: {
          title: "Naziv pogodbe",
          responsible: "Odgovorna oseba",
          updated_at: "Posodobljeno"
        }
      },
      focusgroups: {
        headline: "Odgovornost fokusnih skupin",
        headline_description: "Vse fokusne skupine kamor ste navedeni kot uporabnik. Trenutno vaš račun še ni naveden v nobni fokusni skupini.",
        table: {
          title: "Naziv fokusne skupine",
          responsible: "Odgovorna oseba",
          updated_at: "Posodobljeno"
        }
      }
    }
  }
}
