export default {
  components: {
    universal: {
      search: {
        search: "Iskanje ..."
      },
      table: {
        filters: {
          apply: "Uporabi",
          reset: "Ponastavi",
          close: "Zapri"
        }
      }
    }
  }
}
