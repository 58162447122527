export default {
  views: {
    list: {
      header: {
        title: "Predloge dokumentov",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj predlogo"
      },
      table: {
        title: "Ime predloge",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        author: "Avtor",
        last_modified: "Nazadnje posodobil",
        active: "Aktivna"
      }
    },
    category: {
      title: "Kategorije",
      edit: "Uredi kategorijo",
      new: "Dodaj kategorijo",
      save: "Shrani",
      delete: "Izbriši",
      cancel: "Prekliči",
      edit2: "Uredi",
      fields: {
        title: "Naziv"
      }
    },
    edit: {
      header: {
        remove_button: "Delete",
        back_button: "Na listo predlog dokumentov"
      },
      tabs: {
        properties: "Lastnosti",
        scope: "Podatki"
      },
      upload: {
        file: "Naložite datoteko"
      },
      footer: {
        save_button: "Shrani"
      },
      properties: {
        title: "Ime predloge dokumenta",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        author: "Avtor",
        file: "Datoteka",
        category: "Kategorija",
        view: "Modul predloge dokumenta",
        allowed_export_formats: "Dovoljeni izvozni formati"
      },
      scope: {
        table: {
          model: "Model",
          feature: "Funkcija",
          response_key: "Ključ"
        },
        add_model: "Dodaj model",
        add_relation: "Dodaj relacijo",
        welcome_message: "Tukaj lahko izberete podatke, ki jih želite uporabiti v predlogi."
      }
    }
  },
  popup: {
    title: "Nova predloga dokumentov",
    fields: {
      title: {
        label: "Naziv"
      }
    },
    add_button: "Shrani",
    update_button: "Shrani"
  },
  prompts: {
    delete: {
      title: "Izbris predloge {title}",
      message: "Ste prepričani da želite izbrisati predlogo {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili predlogo dokumentov {title}",
    updated: "Uspešno ste posodobili predlogo dokumentov {title}",
    deleted: "Uspešno ste izbrisali predlogo dokumentov {title}",
    not_found: "Predloga dokumentov ni bila najdena"
  },
  generate_dropdown: {
    resource_select: {
      placeholder: "Generiraj iz predloge",
      document_doesnt_exist: "Dokument ne obstaja"
    }
  }
}
