<template>
    <div class="chat-notification-select-popup__container">
        <UikPopup
            v-if="visible"
            visible
            :max-width="600"
            class="chat-notification-select-popup"
            @close="close()"
        >
            <UikTopBarTitle is="h2">{{ t('title') }}</UikTopBarTitle>
            <div class="uik-popup__scroll-wrapper">
                <UikWidgetContent class="select-container">
                    <UikFormInputGroup>
                        <ResourceSelect
                            :placeholder="t('placeholder')"
                            :label="t('label')"
                            :resource="$api.NotificationTemplateChat"
                            required
                            :remaps="{ key: 'uid', title: 'title' }"
                            sort="title"
                            :search-columns="['title']"
                            :no-results="{
                                text: t('doesnt_exist')
                            }"
                            :filter="{ where: ['type', 'sms'] }"
                            @changeItem="prepareTemplatePayload($event)"
                        />
                    </UikFormInputGroup>
                </UikWidgetContent>

            </div>
        </UikPopup>
        <UikOverlay :visible="visible" class="chat-notification-select-overlay" @close="close()"/>
    </div>
</template>

<script>
import NotificationTemplate from "@/Modules/NotificationTemplate/Resources/NotificationTemplate"

export default {
  langKey: "Chat.notification_select_popup",
  resources: { NotificationTemplateChat: NotificationTemplate },
  props: {
    visible: { type: Boolean, default: false }
  },
  methods: {
    close () {
      this.$emit("close")
    },

    async prepareTemplatePayload (template) {
      this.$emit("template", template)
    }
  }
}
</script>

<style lang="scss">
.chat-notification-select-popup__container {
  .uik-popup {
    .uik-popup__content {
      .select-container {
        min-height: 330px;
      }
      .select-source-container {
        h4 {
          margin: 0;
          line-height: 1.25;
        }
      }
    }
  }

  .chat-notification-select-popup {
    z-index: 2030 !important;
  }
  .chat-notification-select-overlay {
    z-index: 2025 !important;
  }
}
</style>
