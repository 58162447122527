export default {
  views: {
    list: {
      header: {
        title: "Napake",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj"
      },
      table: {
        title: "Naziv",
        description: "Opis",
        key: "Ključ",
        value: "Vrednost",
        read: "Prebrana",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        read_toggle: {
          activate: "Označi kot prebrana",
          deactivate: "Označi kot neprebrana"
        }
      }
    }
  },
  prompts: {
    delete: {
      title: "Izbriši napako {title}",
      message: "Ste prepričani da želite izbrisati napako {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili napako {title}",
    updated: "Uspešno ste posodobili napako {title}",
    deleted: "Uspešno ste izbrisali napako {title}",
    not_found: "Napaka ni bila najdena"
  }
}
