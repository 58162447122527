<template>
    <editor-content :editor="editor" />
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2"
import StarterKit from "@tiptap/starter-kit"

export default {
  components: { EditorContent },
  props: {
    editor: { type: Object, default: null }
  },
  mounted () {
    if (!this.editor) {
      this.editor = new Editor({
        content: "",
        extensions: [
          StarterKit
        ]
      })
    }
  },
  beforeDestroy () {
    this.editor.destroy()
  }
}
</script>

<style lang="scss">
.ProseMirror {
    overflow-y: auto;
    height: 1200px;

    background-color: white;
    padding: 20px 40px;
    margin-top: 25px;

    border: 1px solid #ccc;
    border-radius: 10px;
}
</style>
