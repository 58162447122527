export default {
  views: {
    list: {
      header: {
        title: "Layout Entities",
        search_placeholder: "Search ...",
        add_button: "Add layout entity"
      },
      table: {
        title: "Title",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active"
      }
    },
    details: {
      header: {
        back_button: "Layout Entities list",
        edit_button: "Edit layout entity",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} layout entity here"
        }
      },
      main: {
        active: "Active",
        inactive: "Inactive",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        }
      }
    },
    edit: {
      header: {
        details_back_button: "Back to details",
        list_back_button: "Layout entities list",
        title: "New Layout Entity",
        save_button: "Save layout entity",
        add_button: "Add layout entity",
        remove_button: "Delete"
      }
    }
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Layout entity {title} created",
    updated: "Layout entity {title} updated",
    deleted: "Layout entity {title} was deleted",
    not_found: "Unable to find specified layout entity"
  }
}
