import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/custom-entities",
    name: "Custom Entities",
    meta: { title: "Custom Entities" },
    component: () => import(/* webpackChunkName: "Custom Entities List" */ "@/Modules/CustomEntity/Views/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/custom-entities/new",
    name: "New Custom Entity",
    meta: { title: "New Custom Entity" },
    component: () => import(/* webpackChunkName: "New Custom Entity" */ "@/Modules/CustomEntity/Views/Edit"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/custom-entities/edit/:id",
    name: "Edit Custom Entity",
    component: () => import(/* webpackChunkName: "Edit Custom Entity" */ "@/Modules/CustomEntity/Views/Edit"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/custom-entities/details/:id",
    name: "Custom Entity Details",
    component: () => import(/* webpackChunkName: "Custom Entity Details" */ "@/Modules/CustomEntity/Views/Details"),
    beforeEnter: ifAuthenticated
  }
]
