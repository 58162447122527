export default {
  user: {
    views: {
      list: {
        header: {
          title: "Uporabniki",
          search_placeholder: "Iskanje ...",
          add_button: "Nov uporabnik"
        },
        table: {
          title: "Ime in priimek",
          created_at: "Datum nastanka",
          updated_at: "Datum posodibitve",
          author: "Avtor",
          last_modified: "Nazadnje posodobljeno",
          active: "Aktiven"
        }
      },
      details: {
        header: {
          back_button: "Seznam uporabnikov",
          edit_button: "Uredi uporabnika",
          toggle: {
            title: "Aktiven",
            activate: "akrivirate",
            deactivate: "deaktivirate",
            description: "Uporanika lahko {action} tukaj"
          }
        },
        main: {
          overview: {
            author: "Avtor",
            created_at: "Datum nastanka",
            updated_at: "Datum posodibitve"
          }
        },
        resetPassword: {
          title: "Sprememba gesla",
          subtitle: "Varnost in prijava",
          form: {
            currentPassword: "Trenutno geslo",
            newPassword: "Novo geslo",
            changePassword: "Spremeni geslo",
            passwordRule: "Geslo mora vsebovati najmanj 6 znakov, vsaj eno veliko črko, malo črko, številko in poseben znak"
          },
          feedback: {
            succcess: "Vaše geslo je bilo uspešno spremenjeno!",
            changeItAgain: "Ponovno spremeni?"
          }
        }
      }
    },
    popup: {
      title: "Nov uporabnik",
      fields: {
        name: "Ime in priimek uporabnika",
        username: "Uporabniško ime",
        email: "Elektronski naslov",
        active: {
          description: "Aktivnemu uporabniku ne po treba potrditi svoj elektronski naslov.",
          label: "Aktiven uporabnik"
        }
      },
      add_button: "Shrani uporabnika",
      update_button: "Shrani uporabnika"
    },
    prompts: {
      delete: {
        title: "Izbris uporabnika {name}?",
        message: "Ste prepričani da želite izbrisati uporabnika {name}?",
        cancel: "Prekliči",
        confirm: "Izbriši"
      }
    },
    notify: {
      stored: "Uspešno ste shranili uporabnika {name}",
      updated: "Uspešno ste posodobili uporabnika {name}",
      deleted: "Uspešno ste izbrisali uporabnika {name}",
      not_found: "Uporabnik ni bil najden"
    }
  },
  permissions: {
    title: "Pravice",
    add_button: "Dodaj pravico",
    set_scope_for: "Nastavi obseg za",
    table: {
      columns: {
        permission: "Pravica",
        module: "Modul"
      },
      remove_button: "Odstrani pravico"
    },
    empty_notice: "Ni več pravic za {name}.",
    scope_drawer: {
      title: "Dodaj obseg za {name}",
      cancel_button: "Prekliči",
      confirm_button: "Nastavi obseg"
    }
  },
  access_drawer: {
    title: "Upravljanje uporabnikov",
    search: "Iskanje ...",
    tag: "Število uporabnikov: ",
    table: {
      name: "Ime in priimek"
    },
    cancel_button: "Prekliči",
    save_button: "Shrani"
  },
  one_user_widget: {
    title: "Uporabniški račun",
    email: "Elektronski naslov",
    username: "Uporabniško ime",
    name: "Ime in priimek uporabnika",
    edit_button: "Uredi",
    new_button: "Ustvari",
    redirect: "Ogled uporabnika"
  }
}
