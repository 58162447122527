import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/contracts",
    name: "Contracts",
    meta: { title: "Pogodbe" },
    component: () => import(/* webpackChunkName: "Contracts List" */ "@/Modules/Contract/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/contracts/new",
    name: "New Contract",
    meta: { title: "Nova pogodba" },
    component: () => import(/* webpackChunkName: "New Contract" */ "@/Modules/Contract/Views/Panel/Panel"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/contracts/edit/:id",
    name: "Edit Contract (List)",
    component: () => import(/* webpackChunkName: "Edit Contract" */ "@/Modules/Contract/Views/Panel/Panel"),
    beforeEnter: ifAuthenticated
  }
]
