import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/measures",
    name: "Measures",
    meta: { title: "Meritve" },
    component: () => import(/* webpackChunkName: "Measures List" */ "@/Modules/Measure/Views/List"),
    children: [
      {
        path: "new",
        name: "New Measure",
        meta: { title: "Nova meritev" }
      },
      {
        path: "edit/:id",
        name: "Edit Measure (List)"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/measures/details/:id",
    name: "Measure Details",
    component: () => import(/* webpackChunkName: "Measure Details" */ "@/Modules/Measure/Views/Details"),
    children: [
      {
        path: "edit",
        name: "Edit Measure (Details)"
      },
      {
        path: "users",
        name: "Edit Users Measure (Details)"
      },
      {
        path: "candidates/:id_candidate",
        name: "Measure Candidate (Details)"
      },
      {
        path: "candidate-contacts/:id_can",
        name: "Measure 360 Public Candidate Contacts (Details)"
      },
      {
        path: "results/:structure",
        name: "Measure Results",
        meta: { title: "Rezultati meritve" }
      },
      {
        path: "results",
        name: "Measure 360 Results",
        meta: { title: "Rezultati meritve" }
      },
      {
        path: "public-results",
        name: "Measure Public Results",
        meta: { title: "Rezultati meritve" }
      },
      {
        path: "360-public-results",
        name: "Measure 360 Public Results",
        meta: { title: "Rezultati meritve" }
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
