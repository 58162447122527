export default {
  views: {
    list: {
      header: {
        title: "Aplikacijska opravila",
        search_placeholder: "Iskanje ..."
      },
      table: {
        title: "Ime",
        description: "Opis",
        updated_at: "Nazadnje izvedeno",
        action: "Akcije",
        buttons: {
          execute: "Izvedi"
        }
      },
      prompts: {
        execute: {
          title: "Opravilo {title}",
          message: "Izvedli boste opravilo {title}. Ste prepričani da si to želite?",
          cancel: "Prekliči",
          confirm: "Izvedi"
        }
      }
    }
  }
}
