import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/companies",
    name: "Companies",
    meta: { title: "Podjetja" },
    component: () => import(/* webpackChunkName: "Companies List" */ "@/Modules/Company/Views/List"),
    children: [
      {
        path: "new",
        name: "New Company",
        meta: { title: "Novo podjetje" }
      },
      {
        path: "edit/:id",
        name: "Edit Company (List)"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/companies/details/:id",
    name: "Company Details",
    component: () => import(/* webpackChunkName: "Company Details" */ "@/Modules/Company/Views/Details"),
    children: [
      {
        path: "edit",
        name: "Edit Company (Details)"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
