export default {
  view: {
    header: {
      title: "HR analitika",
      tabs: {
        questionnaire: "Vprašalniki",
        company: "Zrelost",
        candidate: "Kandidati"
      }
    },
    questionnaire: {
      select: {
        industry: "Industrija",
        company: "Podjetje",
        company_not_found: "Podjetje ne obstaja",
        period: {
          label: "Časovno obdobje",
          placeholder: "Izberite obdobje"
        },
        type: "Skupina",
        age: {
          min: "Starost - MIN",
          max: "Starost - MAX"
        },
        gender: "Spol",
        career: "Karierni nivo",
        education: "Izobrazba"
      },
      options: {
        industry: "Vse industrije",
        company: "Vsa podjetja",
        career: "Vsi nivoji",
        education: "Vsa",
        gender: {
          both: "Oba",
          male: "Moški",
          female: "Ženska"
        }
      },
      loading: "Nalaganje podatkov",
      average: "Povprečna ocena",
      average_column: "POVPREČJE",
      competence: "Kompetence"
    },
    company: {
      list: {
        header: {
          title: "Podjetja",
          search_placeholder: "Iskanje ..."
        },
        table: {
          title: "Naziv",
          address: "Naslov",
          category: "Dejavnost (kategorija)",
          subcategory: "Dejavnost (podkategorija)"
        }
      },
      details: {
        header: {
          back_button: "Na listo podjetij"
        },
        tabs: {
          form: "Obrazec",
          charts: "Grafi"
        },
        fields: {
          "condition": "Ocena stanja",
          "desired-condition": "Želeno stanje",
          "strategic-significance": "Strateška pomembnost ",
          "operational-significance": "Operativna pomembnost",
          "development": "Potreba po razvoju glede na strategijo v prvih dveh letih",
          "future": "Pogled v obdobje od 2023 dalje",
          "orientation": {
            label: "Usmeritev",
            options: {
              "accelerated-development": "Pospešeno razvijati",
              "develop": "Razvijati",
              "maintain": "Ohranjati",
              "abandon": "Opustiti"
            }
          }
        },
        form: {
          save_button: "Shrani",
          helper: "LESTVICA ZA VNOS OCEN</br></br>OCENA STANJA in ŽELENO STANJE</br>5: odlično</br>4: zelo dobro</br>3: dobro</br>2: slabo</br>1: zelo slabo</br></br>STRATEŠKA POMEMBNOST in OPERATIVNA POMEMBNOST</br>5: ključno</br>4: visoko pomembno</br>3: pomembno</br>2: manj pomembno</br>1: nepomembno"
        },
        table: {
          set: "Sklop",
          condition: "Obstoječe stanje",
          "desired-condition": "Želeno stanje"
        },
        charts: {
          condition: "Obstoječe stanje",
          "desired-condition": "Želeno stanje"
        },
        set: "Sklop",
        field_of_work: "Področje dela"
      }
    },
    candidate: {
      list: {
        header: {
          title: "Kandidati",
          search_placeholder: "Iskanje ..."
        },
        table: {
          name: "Ime in priimek",
          education: "Izobrazba",
          gender: "Spol",
          birthday: "Rojstni dan",
          career_level: "Karierni nivo",
          competences: "Kompetence",
          show: "Prikaži ",
          hide: "Skrij ",
          age: "Starost"
        },
        apply_filters: "Uporabi filtre",
        value: "Število surovih točk",
        value_denom: "Ocena",
        value_percentile: "Percentil",
        all_candidates: "Vsi kandidati",
        found_candidates: "Najdeni kandidati",
        "found_candidates_%": "Delež najdenih kandidatov"
      }
    }
  }
}
