import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/administration/users-digi",
    name: "Employees",
    meta: { title: "Zaposleni" },
    component: () => import(/* webpackChunkName: "Employees List" */ "@/Modules/Employee/Views/List/List"),
    children: [
      {
        path: "new",
        name: "New Employee",
        meta: { title: "Nov zaposleni" }
      },
      {
        path: "edit/:id",
        name: "Edit Employee (List)"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/administration/users-digi/details/:id",
    name: "Employee Details",
    component: () => import(/* webpackChunkName: "Employee Details" */ "@/Modules/Employee/Views/Details/Details"),
    children: [
      {
        path: "edit",
        name: "Edit Employee (Details)"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
