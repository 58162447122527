<template>
    <div class="empty-list-notice">
        <UikIcon :icon="['fal', 'smile-beam']"/>
        <p>{{ text }}</p>
    </div>
</template>

<script>
export default {
  props: {
    text: { type: String, default: "Odlično. Vaš seznam je prazen." }
  }
}
</script>

<style lang="scss">
.empty-list-notice {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 180px;
    padding-top: 5px;
    svg {
        font-size: 44px;
        color: rgba($textSecondary, 0.7);
        margin-bottom: 10px;
        animation: no-tasks-emoji 1.5s infinite;
        @keyframes no-tasks-emoji {
            0% {
                transform: none;
            }
            35% {
                transform: translateY(-10px) rotate(-10deg);
            }
            70% {
                transform: none;
            }
        }
    }
    position: relative;
    &::after {
        content: "";
        width: 30px;
        height: 8px;
        border-radius: 50%;
        background: rgba($textSecondary, 0.2);
        position: absolute;
        animation: no-tasks-shadow 1.5s infinite;
        margin-top: 5px;
        @keyframes no-tasks-shadow {
            0% {
                width: 30px;
                background: rgba($textSecondary, 0.2);
            }
            35% {
                width: 20px;
                background: rgba($textSecondary, 0.1);
            }
            70% {
                width: 30px;
                background: rgba($textSecondary, 0.2);
            }
        }
    }
    p {
        font-size: 16px;
        color: $textSecondary;
    }
}
</style>
