var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uik-select__wrapper",class:{
        'uik-select__wrapper--open': _vm.state,
        'uik-select__wrapper--loading': _vm.loading
    },attrs:{"disabled":_vm.disabled}},[_c('input',{attrs:{"type":"hidden"},domProps:{"value":_vm.value}}),(_vm.label || _vm.infoText)?_c('div',{staticClass:"uik-input__label-container"},[(_vm.label)?_c('span',{staticClass:"uik-content-title__wrapper uik-input__label"},[_vm._v(" "+_vm._s(_vm.label)+" "+_vm._s((_vm.required ? '*':''))+" ")]):_vm._e(),(_vm.infoText)?_c('UikTooltip',{attrs:{"text":_vm.infoText,"icon":_vm.infoIcon,"noPointerEvents":_vm.infoNoPointerEvents}},[_c('span',{staticClass:"uik-input__info"},[_vm._v("?")])]):_vm._e()],1):_vm._e(),_c('button',{staticClass:"uik-btn__base uik-select__valueRendered",class:{
            'uik-select__valueRendered--error': _vm.errorMessage !== '' ? true : false,
            'uik-select__valueRendered--readonly': _vm.readonly,
            'uik-select__valueRendered--placeholder': _vm.valueRendered === _vm.placeholder
        },attrs:{"type":"button","disabled":_vm.disabled},on:{"click":function($event){(_vm.readonly || _vm.loading) ? null : _vm.open()}}},[(_vm.state)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"search",attrs:{"placeholder":_vm.valueRendered},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.search=$event.target.value},_vm.handleInput],"change":_vm.handleChange,"focus":function($event){return _vm.$emit('focus', $event.target.value)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('keydown-enter', $event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('keydown-esc', $event)}],"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('keyup-enter', $event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('keyup-esc', $event)}]}}):_vm._e(),(!_vm.state)?_c('span',{staticClass:"uik-btn__content"},[_c('div',{staticClass:"uik-select__valueRenderedWrapper"},[(_vm.icon)?_c('Uikon',{staticClass:"uik-select__iconWrapper",attrs:{"icon":_vm.icon}}):_vm._e(),(_vm.value !== '')?_c('div',{staticClass:"uik-select__valueWrapper"},[_vm._v(" "+_vm._s(_vm.valueRendered)+" ")]):_vm._e(),(_vm.value == '')?_c('div',{staticClass:"uik-select__valueWrapper"},[_vm._v(_vm._s(_vm.placeholder))]):_vm._e(),_c('div',{staticClass:"uik-select__arrowWrapper"})],1)]):_vm._e()]),(_vm.state)?_c('div',{class:[
            'uik-select__optionListWrapper',
            'uik-select__' + _vm.dropPosition,
            {'uik-select__optionListWrapper--multiselect': _vm.multiselect}
        ]},[_c('div',{ref:"scrollContainer",staticClass:"uik-select__optionList"},[_c('div',{staticClass:"uik-select__mobile-head"},[_c('div',{staticClass:"uik-select__mobile-head-input"},[_c('Uikon',{attrs:{"icon":"search_left"}}),(_vm.state)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"mobileSearch",attrs:{"placeholder":_vm.valueRendered},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.search=$event.target.value},_vm.handleInput],"change":_vm.handleChange,"focus":function($event){return _vm.$emit('focus', $event.target.value)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('keydown-enter', $event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('keydown-esc', $event)}],"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('keyup-enter', $event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('keyup-esc', $event)}]}}):_vm._e()],1),_c('button',{attrs:{"title":"Close"},on:{"click":function($event){return _vm.close()}}},[_c('Uikon',{attrs:{"icon":"close"}})],1)]),_vm._l((_vm.getOptions),function(option,key){return _c('div',{key:key,staticClass:"uik-btn__base uik-select__option",class:{
                    'uik-select__option--user': _vm.avatar,
                    'uik-select__option--disabled' : option[_vm.remap.disabled]
                },style:(option[_vm.remap.indent] ? 'padding-left: ' + (option[_vm.remap.indent] ? option[_vm.remap.indent] * 5 + 15: 15) + 'px;': null),attrs:{"selected":(Array.isArray(_vm.getOptions) ? option[_vm.remap.key] : key) === _vm.value ? true : false ||
                        (_vm.multiselect && Array.isArray(_vm.value) && _vm.value.includes(Array.isArray(_vm.getOptions) ? option[_vm.remap.key] : key) ? true : false)},on:{"click":function($event){option[_vm.remap.disabled] ? null : _vm.handleSelectedOption(Array.isArray(_vm.getOptions) ? option[_vm.remap.key] : key, option)}}},[(typeof option !== 'object' && !_vm.avatar)?_c('span',{staticClass:"uik-select__optionContent"},[_vm._v(_vm._s(option))]):_vm._e(),(typeof option === 'object' && !_vm.avatar)?_c('span',{staticClass:"uik-select__optionContent uik-btn__content--right"},[_c('span',[_vm._v(_vm._s(option[_vm.remap.title]))]),_c('span',{staticClass:"uik-select__label"},[_vm._v(_vm._s(option[_vm.remap.label]))])]):_vm._e(),(_vm.avatar)?_c('span',{staticClass:"uik-select__optionContent"},[_c('UikAvatar',{attrs:{"imageUrl":option[_vm.remap.imageUrl],"title":option[_vm.remap.title],"name":option[_vm.remap.title],"textBottom":option[_vm.remap.subtitle],"color":"primary"}})],1):_vm._e()])}),(!_vm.loading && _vm.noResults && (!_vm.getOptions || _vm.getOptions.length <= 0))?_c('div',{staticClass:"uik-select__no-results"},[(_vm.noResults.text)?_c('p',[_vm._v(_vm._s(_vm.parseText(_vm.noResults.text)))]):_vm._e(),(_vm.noResults.button)?_c('UikButton',{attrs:{"xs":"","primary":""},on:{"click":function($event){_vm.noResults.button.action()
                        _vm.close()}}},[_vm._v(_vm._s(_vm.noResults.button.text))]):_vm._e()],1):_vm._e()],2)]):_vm._e(),_c('UikOverlay',{attrs:{"visible":_vm.state,"opacity":5},on:{"close":function($event){return _vm.close()}}}),(_vm.errorMessage)?_c('p',{staticClass:"uik-input__errorMessage"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }