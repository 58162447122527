export default {
  views: {
    list: {
      header: {
        title: "Informativne ponudbe",
        search_placeholder: "Iskanje ...",
        toggle: {
          label: "Prikaži analitiko"
        }
      },
      table: {
        title: "Naziv",
        company: "Podjetje",
        contact: "Kontaktna oseba",
        email: "Elektronski naslov",
        phone: "Telefonska številka",
        type: "Storitev",
        created_at: "Ustvarjeno",
        options: "Možnosti"
      }
    },
    analytics: {
      counter: "Št. ponudb v izbranem obdobju:",
      no_selection: "Za izračun seštevka ponudb izberite obdobje."
    },
    details: {
      fields: {
        title: "Naziv",
        company: "Podjetje",
        contact: "Kontaktna oseba",
        email: "Elektronski naslov",
        phone: "Telefonska številka",
        type: "Storitev"
      },
      questions: {
        label: "Vprašanje",
        project: {
          label: "Naziv delovnega mesta, ki ga iščete (opisno)"
        },
        testing: {
          label: "Delovno mesto (opisno)"
        },
        climate: {
          label: "Ali imajo vsi zaposleni službene email naslove?"
        },
        measure360: {
          label: "Ali imajo vsi zaposleni službene email naslove?"
        },
        teambuilding: {
          label: "Število dni?"
        }
      },
      level: {
        label: "Nivo",
        top: "Top management",
        middle: "Middle management",
        professional: "Strokovno",
        leading: "Vodstveno"
      },
      email_question: "Ali imajo vsi zaposleni službene email naslove?",
      email_boolean: "Vsi zaposleni imajo službene email naslove.",
      participants: "Število udeležencev",
      yes: "DA",
      no: "NE",
      workshop: {
        duration: {
          label: "Trajanje delavnice",
          4: "4 ure",
          6: "6 ur",
          8: "8 ur"
        },
        motivation: "Motivacija in samomotivacija",
        cooperation: "Učinkovito sodelovanje",
        teamwork: "Timsko delo",
        communication: "Temelji učikovite komunikacije",
        leadership: "Učinkovito vodenje",
        stress: "Upravljanje stresa",
        emotions: "Čustvena inteligentnost",
        balance: "Obnova energije in uravnoteženost",
        feedback: "Povratna informacija"
      }
    }
  },
  prompts: {
    delete: {
      title: "Izbris informativne ponudbe {title}",
      message: "Ste prepričani da želite izbrisati informativno ponudbo {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili informativno ponudbo {title}",
    updated: "Uspešno ste posodobili informativno ponudbo {title}",
    deleted: "Informativna ponudba {title} je bila izbrisana",
    not_found: "Informativna ponudba ni bila najdena"
  }
}
