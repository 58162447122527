import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/informative-offers",
    name: "Informative Offers",
    meta: { title: "Informativne ponudbe" },
    component: () => import(/* webpackChunkName: "Informative Offers List" */ "@/Modules/InformativeOffer/Views/List/List"),
    children: [
      {
        path: "details/:id",
        name: "Edit Informative Offers (List)"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
