import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/tasks",
    name: "Tasks",
    meta: { title: "Opravila" },
    component: () => import(/* webpackChunkName: "Task Details" */ "@/Modules/Task/Views/Details/Details"),
    beforeEnter: ifAuthenticated
  }
]
