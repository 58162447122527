<template>
    <div v-if="false"/>
</template>

<script>
export default {
  props: {
    categories: { type: Array, default: () => [] }
  },
  created () {
    this.fetchCodelists()
  },
  methods: {
    fetchCodelists () {
      if (this.categories.length > 0 && this.userCan("CodelistData/view")) {
        this.$api.Codelist.get({ params: this.categories }).then(res => {
          if (res.status === 200) this.$emit("ready")
        })
      } else {
        this.$emit("ready")
      }
    }
  }
}
</script>
