export default {
  views: {
    list: {
      header: {
        title: "Podatki za lijak",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj"
      },
      table: {
        candidates_count: "Št. kandidatov",
        title: "Naziv",
        reason: "Razlog",
        options: "Možnosti",
        color: "Barva",
        place: "Zap. mesto"
      }
    }
  },
  popup: {
    title: "Podatek za lijak",
    fields: {
      candidates_count: "Št. kandidatov",
      title: "Naziv",
      reason: "Razlog",
      place: "Zaporedno mesto"
    },
    add_button: "Shrani",
    update_button: "Shrani"
  },
  prompts: {
    delete: {
      title: "Izbriši podatek",
      message: "Ste prepričani da želite izbrisati podatek?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili podatek",
    updated: "Uspešno ste posodobili podatek",
    deleted: "Uspešno ste izbrisali podatek",
    not_found: "Podatek ni bil najden"
  }
}
