import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/focus-groups",
    name: "Focus Groups",
    meta: { title: "Fokusne skupine" },
    component: () => import(/* webpackChunkName: "Focus Groups List" */ "@/Modules/FocusGroup/Views/List/List"),
    children: [
      {
        path: "templates/new",
        name: "New Focus Group Template",
        meta: { title: "Nova predloga" }
      },
      {
        path: "templates/edit/:id",
        name: "Edit Focus Group Template (List)"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/focus-groups/new",
    name: "New Focus Group",
    meta: { title: "Nova fokusna skupina" },
    component: () => import(/* webpackChunkName: "New Focus Group" */ "@/Modules/FocusGroup/Views/Panel/Panel"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/focus-groups/edit/:id",
    name: "Edit Focus Group (List)",
    component: () => import(/* webpackChunkName: "Edit Focus Group" */ "@/Modules/FocusGroup/Views/Panel/Panel"),
    beforeEnter: ifAuthenticated
  }
]
