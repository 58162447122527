import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/administration/jobs",
    name: "Jobs",
    meta: { title: "Aplikacijska opravila" },
    component: () => import(/* webpackChunkName: "Jobs List" */ "@/Modules/Job/Views/List/List"),
    beforeEnter: ifAuthenticated
  }
]
