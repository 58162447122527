import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/layout-entities",
    name: "Layout Entities",
    meta: { title: "Layout Entities" },
    component: () => import(/* webpackChunkName: "Layout Entities List" */ "@/Modules/LayoutEntity/Views/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/layout-entities/new",
    name: "New Layout Entity",
    meta: { title: "New Layout Entity" },
    component: () => import(/* webpackChunkName: "New Layout Entity" */ "@/Modules/LayoutEntity/Views/Edit"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/layout-entities/edit/:id",
    name: "Edit Layout Entity",
    component: () => import(/* webpackChunkName: "Edit Layout Entity" */ "@/Modules/LayoutEntity/Views/Edit"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/layout-entities/details/:id",
    name: "Layout Entity Details",
    component: () => import(/* webpackChunkName: "Layout Entity Details" */ "@/Modules/LayoutEntity/Views/Details"),
    beforeEnter: ifAuthenticated
  }
]
