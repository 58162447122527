export default {
  title: "Administration",
  Data: {
    Modules: {
      employees: {
        title: "Uporabniki DIGI",
        description: "Ustvari, izbriši in urejaj zaposlene."
      },
      users: {
        title: "Uporabniki",
        description: "Ustvari, izbriši in urejaj uporabnike."
      },
      userGroups: {
        title: "Uporabniške skupine",
        description: "Ustvari, izbriši in urejaj uporabniške skupine."
      },
      codelists: {
        title: "Šifranti",
        description: "Vsi vnaprej določeni ter spremenljivi šifranti v aplikaciji."
      },
      documentTemplates: {
        title: "Predloge dokumentov",
        description: "Predloge dokumentov za generiranje dokumentov v aplikaciji."
      },
      notifications: {
        title: "Obvestila",
        description: "Seznam poslanih obvestil"
      },
      notificationTemplates: {
        title: "Predloge obvestil",
        description: "Predloge, ki se uporabljajo za generiranje obvestil v vaši aplikaciji"
      },
      jobs: {
        title: "Aplikacijska opravila",
        description: "Izvajanje aplikacijskih opravil."
      }
    }
  }
}
