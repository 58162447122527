export default {
  views: {
    list: {
      header: {
        title: "Layouts",
        search_placeholder: "Search ...",
        add_button: "Create new layout"
      },
      table: {
        columns: {
          title: "Title",
          key: "View",
          last_modified: "Last modified"
        }
      }
    },
    builder: {
      header: {
        back_button: "Back",
        default_layout_button: "Set default layout"
      },
      layout: {
        remove_column_button: "Remove column",
        add_column_button: "Add column",
        remove_row_button: "Remove row",
        add_row_button: "Add row",
        type: "Type",
        types: {
          columns: "Simple layout",
          grid: "Grid layout"
        },
        width: "Width",
        add_widget_button: "Place {widget} here",
        move_widget_button: "Move {widget}",
        remove_widget_button: "Remove {widget}"
      },
      footer: {
        undo_button: "Undo all changes",
        save_button: "Save changes",
        save_button_saved: "Saved"
      },
      notify: {
        update_success: "{title} successfully updated",
        save_error: "An error occured while saving"
      },
      before_leave_alert: {
        title: "Changes you made are not saved",
        message: "You're about to leave this page without saving. Your changes will be discarded. Are you sure you'd like to leave?",
        confirm_button: "Leave",
        cancel_button: "Stay on this page"
      }
    }
  },
  components: {
    popup: {
      title: "Create new layout",
      fields: {
        title: "Title",
        key: "View"
      },
      notify: {
        add_success: "Successfully created layout {title}"
      },
      close_button: "Close",
      confirm_button: "Create layout"
    }
  }
}
