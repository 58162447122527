import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/finances",
    name: "Finances",
    meta: { title: "Finančni modul" },
    component: () => import(/* webpackChunkName: "Finances" */ "@/Modules/Finances/Views/View"),
    children: [
      {
        path: "idents/new",
        name: "New Finance Ident",
        meta: { title: "Nov ident" }
      },
      {
        path: "idents/edit/:id_ident",
        name: "Edit Finance Ident"
      },
      {
        path: "employees/new",
        name: "New Finance Employee",
        meta: { title: "Nov zaposleni" }
      },
      {
        path: "employees/edit/:id_employee",
        name: "Edit Finance Employee"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
