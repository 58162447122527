export default {
  views: {
    list: {
      header: {
        title: "Media",
        search_placeholder: "Search ...",
        add_button: "Add media"
      },
      table: {
        title: "Title",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active"
      }
    },
    details: {
      header: {
        back_button: "Media list",
        edit_button: "Edit media",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} media here"
        }
      },
      main: {
        active: "Active",
        inactive: "Inactive",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        }
      }
    }
  },
  popup: {
    title: "New Media",
    fields: {
      title: {
        label: "Title"
      }
    },
    add_button: "Add media",
    update_button: "Update media"
  },
  prompts: {
    delete: {
      title: "Izbris datoteke {name}",
      message: "Ste prepričani da želite izbrisati datoteko {name}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Datoteka {name} je bila ustvarjena",
    updated: "Datoteka {name} je bila posodobljena",
    deleted: "Datoteka {name} je bila izbrisana",
    not_found: "Datoteke ni mogoče najti"
  },
  widget: {
    title: "Datoteke",
    title_cv: "CV in datoteke",
    upload: "Naloži datoteko",
    upload_cv: "Naloži CV",
    generate: "Generiraj iz predloge",
    cv: "CV",
    other: "Other",
    table: {
      title: "Naziv",
      collection: "Tip",
      type_vincere: "Tip (Vincere)",
      remove_button: "Izbriši",
      created_at: "Ustvarjeno",
      visible: "Viden naročniku",
      yes: "DA",
      no: "NE"
    },
    collection_name_mapper: {
      default: "Privzeta",
      cv: "CV (naložil kandidat)",
      avatar: "Avatar"
    },
    report: {
      title: "Poročilo psihologa",
      empty: "Poročilo psihologa ni naloženo",
      prompts: {
        upload: {
          title: "Nalaganje poročila psihologa",
          message: "Poročilo psihologa že obstaja. Ob naložitvi novega poročila, se bo staro poročilo izbrisalo. Ali želite naložiti novo poročilo?",
          cancel: "Prekliči",
          confirm: "Naloži"
        }
      }
    },
    report_consultant: {
      title: "Poročilo svetovalca",
      empty: "Poročilo svetovalca ni naloženo",
      prompts: {
        upload: {
          title: "Nalaganje poročila svetovalca",
          message: "Poročilo svetovalca že obstaja. Ob naložitvi novega poročila, se bo staro poročilo izbrisalo. Ali želite naložiti novo poročilo?",
          cancel: "Prekliči",
          confirm: "Naloži"
        }
      }
    },
    loading_cv: "Procesiranje CVja...",
    loading_cv_description: "Podatki iz CVja bodo dodani kandidatu."
  }
}
