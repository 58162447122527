export default {
  popup: {
    title: "Sporočila",
    show_candidate: "Prikaži kandidata",
    show_employee: "Prikaži zaposlenega",
    send: "Pošlji SMS",
    loading_msgs: "Nalaganje sporočil",
    loading_contacts: "Nalaganje uporabnikov",
    msg_placeholder: "Vnesite sporočilo...",
    fill_from_template: "Prenesi sporočilo iz predloge",
    empty_history: "Zgodovina sporočil je prazna.",
    wrong_number_format: "Telefonska številka ne sme imeti presledkov in posebnih znakov.</br>Primeri veljavnih formatov:</br>• 080123456</br>• 0038680123456</br>• +38680123456",
    no_number: "Kandidat nima telefonske številke.",
    project: {
      label: "Izberi iskanje in selekcijo",
      not_found: "Iskanje in selekcija ne obstaja",
      null: "Brez filtra",
      redirect: "Odpri iskanje in selekcijo"
    },
    tooltip_projects: {
      participated: "Kandidat je sodeloval v naslednjih iskanjih in selekcijah:",
      not_participated: "Kandidat ni sodeloval v iskanju in selekciji"
    },
    error: "Pri pošiljanju je prišlo do napake."
  },
  notification_select_popup: {
    title: "Prenesi sporočilo iz predloge",
    label: "Predloga obvestil",
    placeholder: "Izberite predlogo obvestil",
    doesnt_exist: "Predloga obvestil ne obstaja"
  }
}
